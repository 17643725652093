import React, { Component } from "react";
import { Form, ModalBody, ModalFooter, Alert } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
	WhFieldTxt,
	WhFieldSelect2,
} from "../../components/Form/whFields";
import { getLocal } from "../../reducers/params";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";

class UserForm extends Component {
	test = () => {};

	render() {
		const { error, handleSubmit, submitting, roles } = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-horizontal">
				<ModalBody>
					<Field
						label="Email :"
						name="email"
						component={WhFieldTxt}
						type="text"
					/>

					<Field
						label="Rôles :"
						name="roles"
						component={WhFieldSelect2}
						type="select"
						options={roles}
					/>

					{error && (
						<Alert color="danger">
							<div dangerouslySetInnerHTML={{ __html: error }} />
						</Alert>
					)}
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type="submit"
					>
						<i className="fa fa-save" />
						&nbsp;Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

const formName = "FormUser";

const rxf = reduxForm({ form: formName })(UserForm);

UserForm.defaultProps = {
	error: "",
	local: null,
	currentValues: {},
};
UserForm.propTypes = {
	error: PropTypes.string,
	local: PropTypes.string,
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	currentValues: PropTypes.shape({}),
};

export const mapStateToProps = state => ({
	local: getLocal(state),
});

export default connect(mapStateToProps)(rxf);
