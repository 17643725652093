const userReducer = (state = {}, { payload, type }) => {
    switch (type) {
        case 'SET_USER':
            return { ...payload };

        default:
            return state;
    }
};

export default userReducer;
