import { fetch } from "../../utils/fetch";
import QueryBuilder from "../../components/WHTable/queryBuilder";
import configApi from "../../constants/ApiConfig";

const endPoints = "/api/media";

export const viewFromUrl = url =>
	fetch(`${configApi.apiAssetUrl}${url}`, {}, "get", {}, {}, null, false);

    export const search = (data) => {
        let query = QueryBuilder(data);
    
        return fetch(`${configApi.apiUrl}${endPoints}`, 'get', query, {}, null, false)
    };
    
export const view = (id, lang = null) => fetch(`${configApi.apiUrl}${endPoints}/${id}`, "get", {}, {}, lang ? {'X-LOCALE':lang} : null, false);

export const create = (data) => fetch(`${configApi.apiUrl}${endPoints}`, 'post', data, {}, null, false);

export const update = (data, lang = null) => fetch(`${configApi.apiUrl}${endPoints}/${data.id}`, "put", data, {}, lang ? {'X-LOCALE':lang} : null, false);

export const remove = (id) => fetch(`${configApi.apiUrl}${endPoints}/${id}`, 'delete', {}, {}, {}, false );
    
