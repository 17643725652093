import React, {Component} from 'react';
import {Modal, ModalHeader} from 'reactstrap';
import * as tagsServices from '../../services/tag';
import {SubmissionError} from 'redux-form';
import {toast} from 'react-toastify';
import Loading from '../../components/Loading';
import TagForm from "./TagForm";


class CreateEditPopup extends Component {


    state = {
        open: false,
        data: {},
        loaded: true,
        locale: 'fr'
    };


    handleOpen = (id = null, locale = 'fr') => {
        this.setState({
            open: true,
            locale
        });

        if (id) {
            this.setState({
                loaded: false
            });

            tagsServices.view(id, locale).then((data) => {
                this.setState({
                    data,
                    loaded: true
                });
            });
        }
    };

    handleClose = () => {
        this.setState({
            open: false,
            data: {}
        });
    };

    onSubmit = (data) => {

        const edit = (!data.id) ? tagsServices.create : tagsServices.update;


        const toSubmitData = {...data, createdFromAdmin: true}

        return edit(toSubmitData, this.state.locale).then((data) => {

            this.props.newData(data);
            toast.success('Enregistrement réussi');
            this.handleClose();
        }).catch((error) => {
            throw new SubmissionError(error);
        });
    };

    render() {
        const {className} = this.props;
        const {loaded, open, locale} = this.state;

        return (

            <Modal isOpen={open} className={className} fade={false}>

                <ModalHeader toggle={this.handleClose}>Editer</ModalHeader>

                {!loaded &&
                <Loading/>
                }

                {loaded &&
                <div>
                    <TagForm onSubmit={this.onSubmit} initialValues={this.state.data} locale={locale}/>
                </div>
                }

            </Modal>

        );

    }
}

export default CreateEditPopup