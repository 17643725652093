const missions01 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Bloc ",
        fields: {
            background: {
                admin: {
                    template: "BlocMedia",
                    type: "tplt-picture",
                },
            },
            title: {
                admin: {
                    template: "BlocTitle",
                    content: 'Trouvez la bonne mission dans votre univers d’experts !',
                },
            },
        },
    },
};

export default {
    template: "missions",
    name: "Missions",
    default: true,
    seo: {
        robots: "index,follow",
    },
    builder: [missions01],
};