import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import Header from "./Header/";
import Sidebar from "./Sidebar/";
import Breadcrumb from "./Breadcrumb/";
import Footer from "./Footer/";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import * as session from "../../utils/session";
import Dashboard from "../Dashboard/";
import { bindActionCreators } from "redux";
import { setUser, getUserRoles, getUser } from "../Authentication/redux";
import MyInformations from "../Account/MyInformations";
import MyPassword from "../Account/MyPassword";
import Users from "../Users";
import Parameters from "../Parameters";
import Maintenance from "../Maintenance";

import { setParams } from "../../reducers/params";
import Pages from "../Cms/Pages/Pages";
import Page from "../Cms/Pages/Page";

import Seos from "../Cms/Seo/Seos";
import Menu from "../Cms/Menus/Menus";
import Loading from "../../components/Loading";
import Branches from "../Branches";
import Tags from "../Tags";

import Advantages from "../Partners/Advantages";
import Partners from "../Partners";
import Members from "../Members";
import Testimonies from "../Testimonies";
import Subjects from "../Cms/Contact/Subject";
import Universes from "../Universe/Universe";
import UniversView from "../Universe/Universe/UniversView";
import SubUniverses from "../Universe/SubUniverse";
import SubUniversView from "../Universe/SubUniverse/SubUniversView";
import JobView from "../Universe/Job/JobView";
import Jobs from "../Universe/Job";
import PostView from "../Universe/Post/PostView";
import Posts from "../Universe/Post";
import TagsUniverses from "../Tags/indexTagsUniverses";

class Layout extends Component {
	state = { loaded: false };

	componentDidMount() {
		this.checkAuthentication();
	}

	checkAuthentication = async () => {
		const { setParams, setUser } = this.props;

		try {
			await setParams();
			const data = await session.isAuthenticated();
			setUser(data);
			this.setState({ loaded: true });
		} catch (error) {
			this.props.history.push("/login");
		}
	};

	render() {
		const { loaded } = this.state;
		const { userRoles } = this.props;

		if (!loaded) return <Loading />;
		return (
			<div className="app">
				<Header />
				<ToastContainer
					position="top-center"
					hideProgressBar
					autoClose={3000}
				/>
				<div className="app-body">
					<Sidebar {...this.props} />

					<main className="main">
						<Breadcrumb />
						<Container fluid>
							<Switch>
								<Route
									path="/dashboard"
									name="Dashboard"
									component={Dashboard}
								/>

								<Route
									path="/account/informations"
									name="Mon compte"
									component={MyInformations}
								/>

								<Route
									path="/account/password"
									name="Mon compte"
									component={MyPassword}
								/>

								<Route
									path="/users"
									name="Gestion des utilisateurs"
									component={Users}
								/>

								<Route
									path="/pages/edit/:id"
									name="Gestion des pages"
									component={Page}
								/>
								<Route
									path="/pages/edit"
									name="Gestion des pages"
									component={Page}
								/>

								<Route
									path="/pages"
									name="Gestion des Articles"
									component={Pages}
								/>
								<Route
									path="/sujets-contact"
									name="Gestion des Sujets de contact"
									component={Subjects}
								/>
								<Route
									exact
									path="/seos"
									name="Gestion du seo"
									component={Seos}
								/>

								<Route
									path="/menus"
									name="Gestion des Menus"
									component={Menu}
								/>

								<Route
									path="/parametres"
									name="Gestion des paramètres"
									component={Parameters}
								/>


                <Route
                  path="/maintenance"
                  name="Gestion de la maintenance"
                  component={Maintenance}
                />

								<Route
									path="/branches"
									name="Gestion des Secteurs"
									component={Branches}
								/>

								<Route
									path="/tags/univers"
									name="Gestion des tags avec une Page"
									component={TagsUniverses}
								/>

								<Route
									path="/tags/:iswaiting"
									name="Gestion des tags"
									component={Tags}
								/>

								<Route
									path="/avantages"
									name="Gestion des avantages"
									component={Advantages}
								/>

								<Route
									path="/partenaires"
									name="Gestion des partenaires"
									component={Partners}
								/>

								<Route
									path="/membres"
									name="Gestion des membres de l'équipe"
									component={Members}
								/>

								<Route
									path="/temoignages"
									name="Gestion des Témoignages"
									component={Testimonies}
								/>

								<Route
									path="/univers/edit/:id"
									name="Gestion des Univers"
									component={UniversView}
								/>

								<Route
									path="/univers/creer"
									name="Gestion des Univers"
									component={UniversView}
								/>

								<Route
									path="/univers"
									name="Gestion des Univers"
									component={Universes}
								/>

								<Route
									path="/sous-univers/edit/:id"
									name="Gestion des Sous Univers"
									component={SubUniversView}
								/>

								<Route
									path="/sous-univers/creer"
									name="Gestion des Sous Univers"
									component={SubUniversView}
								/>

								<Route
									path="/sous-univers"
									name="Gestion des Sous Univers"
									component={SubUniverses}
								/>

								<Route
									path="/jobs/edit/:id"
									name="Gestion des pages métiers"
									component={JobView}
								/>

								<Route
									path="/jobs/creer"
									name="Gestion des pages métiers"
									component={JobView}
								/>

								<Route
									path="/jobs"
									name="Gestion des pages métiers"
									component={Jobs}
								/>

								<Route
									path="/news/edit/:id"
									name="Gestion des News"
									component={PostView}
								/>

								<Route
									path="/news/creer"
									name="Gestion des News"
									component={PostView}
								/>

								<Route
									path="/news"
									name="Gestion News"
									component={Posts}
								/>

								<Redirect from="/" to="/dashboard" />
							</Switch>
						</Container>
					</main>
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: getUser(state),
	userRoles: getUserRoles(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators({ setUser, setParams }, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Layout);
