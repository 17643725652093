const team01 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/team/team01.html.twig",
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Titre",
        fields: {
            title: {
                admin: {
                    template: "BlocTitle",
                    content:
                        '<h2 class="size-title weight-light">Trouvez la mission !</h2>',
                },
            },
            subtitle: {
                admin: {
                    template: "BlocTitle",
                    content:
                        ' <h2 class="size-title weight-bold">Nos missions freelance par univers</h2>',
                },
            },
            paragraphe: {
                admin: {
                    template: "BlocText1",
                    content:
                        ' <p class="size-small weight-light w-80 mt-10">Notre équipe de professionnels est présent pour vous accompagner tout au long de votre projet. Leur mission ? Vous apporter soutien, conseils et serenité. Découvrez en d’avantage en nous contactant ! </p>',
                },
            },
            button: {
                admin: {
                    template: "BlocTitle",
                    content: 'Découvrir nos engagements'
                }
            }
        },
    },
};

/**
 * EXPORT
 */
export default {
    template: "team",
    name: "Notre équipe",
    editable: true,
    add: false,
    seo: {
        robots: "index,follow",
    },
    builder: [team01],
};
