import React from "react";
import { Field } from "redux-form";
import { Card, CardBody, CardHeader, Button, Row, Col } from "reactstrap";
import { WhFieldTxt } from "./index";
import * as mediaService from "../../../services/media";
import * as apiMediaService from "../../../services/apiMedia";
import * as sessionService from "../../../utils/session/index";
import ApiConfig from "../../../constants/ApiConfig";

class File extends React.Component {
	state = {
		closed: [],
		media: this.props.input.value,
		input: this.props.input,
	};

	openFinder = (id, apiUrl) => {
		const { maxWidth, maxHeight, width, height } = this.props;

		const token = sessionService.getJwtToken();

		const params = {
			type: "id",
			url: apiUrl ? ApiConfig.finderApiParams.url : ApiConfig.finderParams.url,
			endPointMedia: apiUrl ? ApiConfig.finderApiParams.endPointMedia : ApiConfig.finderParams.endPointMedia,
			assetUrl: apiUrl ? ApiConfig.finderApiParams.assetUrl : ApiConfig.finderParams.assetUrl,
			id,
		};

		if (!apiUrl)  params.access_token = `${token}`;

		if (maxWidth) params.maxWidth = maxWidth;
		if (maxHeight) params.maxHeight = maxHeight;
		if (width) params.width = width;
		if (height) params.height = height;

		let str = "";
		for (let key in params) {
			if (str != "") {
				str += "&";
			}
			str += key + "=" + encodeURIComponent(params[key]);
		}

		const url = `/wh-finder/index.html?${str}`;

		window.open(
			url,
			"wh media center",
			`location=no, menubar=no, status=no, scrollbars=no, menubar=no, width=${
				window.screen.availWidth
			}, height=${window.screen.availHeight}`,
		);
	};

	onUrlChange = (eventOrValue, apiUrl) => {

		let service = apiUrl ? apiMediaService: mediaService;
		if (eventOrValue) {
			service.view(eventOrValue.target.value).then(data => {
				this.state.input.onChange(data);
				this.setState({ media: data });
			});
		} else {
			this.state.input.onChange(null);
			this.setState({ media: null });
		}
	};
	render() {
		const { label, withHeader, apiUrl = false } = this.props;

		return (
			<Card className="whFile">
				{withHeader && (
					<CardHeader>
						<i className="fa fa-image" />
						{label}
					</CardHeader>
				)}
				<CardBody>
					<Field
						label={withHeader ? false : label}
						name={`${this.state.input.name}.id`}
						id={`mediaField${this.state.input.name}`}
						type="text"
						className="mediaField"
						component={WhFieldTxt}
						placeholder="Media"
						onChange={event => this.onUrlChange(event, apiUrl)}
					/>

					{this.state.media &&
						this.state.media.url &&
						this.state.media.type != "image" && (
							<span>{this.state.input.url}</span>
						)}

					{this.state.media &&
						this.state.media.url &&
						this.state.media.type == "image" && (
							<img src={ apiUrl ? `${ApiConfig.finderApiParams.assetUrl}${this.state.media.url}` : `${ApiConfig.finderParams.assetUrl}${this.state.media.url}`}
								style={{ maxWidth: "100%" }}
							/>
						)}

					<Row>
						<Col>
							<Button
								color="success"
								block
								onClick={() =>
									this.openFinder(`mediaField${this.state.input.name}`, apiUrl)
								}
							>
								<i className="fa fa-upload" />
							</Button>
						</Col>
						<Col>
							<Button
								color="danger"
								block
								onClick={() => this.onUrlChange(null, apiUrl )}
							>
								<i className="fa fa-trash" />
							</Button>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}

export default File;
