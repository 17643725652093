import React, {Component} from 'react';
import {Modal, ModalHeader} from 'reactstrap';
import * as branchService from '../../services/branch';
import {SubmissionError} from 'redux-form';
import {toast} from 'react-toastify';
import Loading from '../../components/Loading';
import BranchForm from "./BranchForm";

class CreateEditPopup extends Component {


    state = {
        open: false,
        data: {},
        loaded: true,
        locale: 'fr'
    };


    handleOpen = (id = null, locale = 'fr') => {
        this.setState({
            open: true,
            locale
        });

        if (id) {
            this.setState({
                loaded: false
            });

            branchService.view(id, locale).then((data) => {
                data.sourcesArray = data.sources && data.sources.split(',').map( val => ({link: val}));
                data.subUniverses =data.subUniverses.map(el => {
                        return {...el, value : el.id, label: el.title }
                });
                data.jobs =data.jobs.map(el => {
                    return {...el, value : el.id, label: el.title }
                });


                this.setState({
                    data,
                    loaded: true
                });
            });
        }
    };

    handleClose = () => {
        this.setState({
            open: false,
            data: {}
        });
    };

    onSubmit = (data) => {

        if(data.sourcesArray) {
            data.sources = data.sourcesArray.map(val => val.link).join(',');
        }

        const edit = (!data.id) ? branchService.create : branchService.update;

        return edit(data, this.state.locale).then((data) => {
            this.props.newData(data);

            toast.success('Enregistrement réussi');

            this.handleClose();
        }).catch((error) => {
            throw new SubmissionError(error);
        });
    };

    render() {
        const {className} = this.props;
        const {loaded, open, locale} = this.state;

        return (

            <Modal size={'lg'} isOpen={open} className={className} fade={false}>

                <ModalHeader toggle={this.handleClose}>Editer</ModalHeader>

                {!loaded &&
                <Loading/>
                }

                {loaded &&
                <div>
                    <BranchForm onSubmit={this.onSubmit} initialValues={this.state.data} locale={locale} />
                </div>
                }

            </Modal>

        );

    }
}

export default CreateEditPopup