import React, {Component} from 'react';
import {Form} from 'reactstrap';
import {Field, reduxForm} from 'redux-form'
import {ModalBody, ModalFooter} from 'reactstrap';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';

import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {getLocal} from "../../reducers/params";
import {WhFile, WhFieldSelect, WhValidator, WhFieldCkEditor, WhFieldSelect2, WhFieldTxt} from "../../components/Form/whFields";
import {
    getCmsStatus,
} from "../../reducers/params";

class PartnerForm extends Component {
    state = {
        open: false
    };

    render = () => {
        const { handleSubmit, submitting, cmsStatus, advantages, locale} = this.props;

        const style = locale ? locale == 'en' ? {
            backgroundSize: "20px",
            backgroundImage: `url("/img/en.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        } : {
            backgroundSize: "20px",
            backgroundImage: `url("/img/fr.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        } : {
            backgroundSize: "20px",
            backgroundImage: `url("/img/fr.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        };

        return (

            <Form onSubmit={handleSubmit} className="form-horizontal">

                <ModalBody>

                    <Field
                        label="Nom :"
                        name="name"
                        component={WhFieldTxt}
                        type="text"
                        style={style}
                    />

                    <Field
                        label="Secteur :"
                        name="sector"
                        component={WhFieldTxt}
                        type="text"
                        style={style}
                    />

                    <Field
                        label="Selectionner les avantages de ce partenaire :"
                        name="advantages"
                        component={WhFieldSelect2}
                        type="select"
                        options={advantages}
                    />

                    <Field
                        label="Description :"
                        name="description"
                        component={WhFieldCkEditor}
                        type="textarea"
                        style={style}
                    />

                    <Field
                        label="Statut de publication :"
                        name="status"
                        required
                        validate={[WhValidator.required]}
                        component={WhFieldSelect}
                        type="select"
                        options={cmsStatus}
                        style={style}
                    />

                    <Field
                        label="Url externe ? :"
                        name="externalUrl"
                        component={WhFieldTxt}
                        type="text"
                        style={style}
                    />

                    <Field
                        label="Logo"
                        name="logo"
                        component={WhFile}
                        type="text"
                    />

                </ModalBody>

                <ModalFooter>

                    <LaddaButton
                        className="btn btn-success btn-ladda"
                        loading={submitting}
                        data-style={EXPAND_LEFT}
                        type={'submit'}
                    >
                        <i className="fa fa-save"> </i>
                        &nbsp;Enregistrer
                    </LaddaButton>

                </ModalFooter>

            </Form>

        );

    }
}

PartnerForm.propTypes = {
    error: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    local: PropTypes.string
};
PartnerForm.defaultProps = {
    error: "",
};

const mapStateToProps = (state) => ({
    local: getLocal(state),
    cmsStatus: getCmsStatus(state),
});

PartnerForm = connect(
    mapStateToProps
)(PartnerForm);

export default reduxForm({
    form: 'BranchForm'
})(PartnerForm);