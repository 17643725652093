import React from "react";
import classnames from "classnames";
import Title from "./title";

class BlocTitle extends React.Component {
	render = () => {
		const { onSelect, active, data } = this.props;

		return (
			<div
				className={classnames("tpl-bloc", { active: active })}
				onClick={onSelect}
			>
				<Title data={data} {...this.props} />
			</div>
		);
	};
}

export default BlocTitle;
