import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import MaintenanceForm from "./MaintenanceForm";
import * as maintenanceService from "../../services/maintenance";
import { SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";

class CreateEditPopup extends Component {
	state = {
		open: false,
		data: {},
		loaded: true,
		locale: 'fr'
	};

	handleOpen = (id = null, locale = 'fr') => {
		this.setState({
			open: true,
			locale
		});

		if (id) {
			this.setState({
				loaded: false,
			});

		}
	};

	handleClose = () => {
		this.setState({
			open: false,
			data: {},
		});
	};

	onSubmit = data => {


		return maintenanceService.create(data)
			.then(data => {
				this.props.newData(data);

				toast.success("Enregistrement réussi");

				this.handleClose();
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	render() {
		const { className } = this.props;
		const { loaded, open, locale } = this.state;

		return (
			<Modal isOpen={open} className={className} fade={false}>
				<ModalHeader toggle={this.handleClose}>Editer</ModalHeader>

				{!loaded && <Loading />}

				{loaded && (
					<MaintenanceForm
						onSubmit={this.onSubmit}
						initialValues={this.state.data}
						locale={locale}
					/>
				)}
			</Modal>
		);
	}
}

export default CreateEditPopup;
