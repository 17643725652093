import React, { Component } from "react";
import {
	Field,
	FormSection,
	reduxForm,
	FieldArray,
	change,
	getFormValues,
} from "redux-form";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { WhFieldTxt, WhFieldSelect } from "../..";
import { Form, ModalBody, ModalFooter, Alert, Row, Col } from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";

class FormPopup extends Component {
	state = {
		template: null,
	};

	selectTplt = data => {
		const { defaultTemplates, change } = this.props;
		change("admin", defaultTemplates[data.target.value].admin);
		change("tplt", defaultTemplates[data.target.value].admin.tplt);
		change("slug", defaultTemplates[data.target.value].admin.slug);
		change("id", defaultTemplates[data.target.value].admin.id);
	};

	render() {
		const {
			error,
			handleSubmit,
			submitting,
			currentValues,
			defaultTemplates,
		} = this.props;

		const tplts = [];

		Object.keys(defaultTemplates).map((objectKey, objectIndex) => {
			const el = defaultTemplates[objectKey];
			tplts.push({
				value: objectKey,
				name: el.admin.title,
			});
		});

		return (
			<Form className="form-vertical">
				<ModalBody>
					<Field
						label={false}
						name="admin.title"
						component={WhFieldTxt}
						type="text"
						placeholder="Nom du bloc"
					/>
					<Row>
						<Col>
							<Field
								label={false}
								name="slug"
								component={WhFieldTxt}
								type="text"
								placeholder="Slug"
							/>
						</Col>
						<Col>
							<Field
								label={false}
								name="tplt"
								component={WhFieldTxt}
								type="text"
								placeholder="Render"
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<Field
								label={false}
								name="id"
								component={WhFieldTxt}
								type="text"
								placeholder="Id"
							/>
						</Col>
					</Row>
					Todo : User intefarce et mise en page + apperçu (slider,
					flap )
					<Field
						label={false}
						name="template"
						component={WhFieldSelect}
						onChange={data => this.selectTplt(data)}
						options={tplts}
					/>
					{error && (
						<Alert color="danger">
							<div
								dangerouslySetInnerHTML={{
									__html: error,
								}}
							/>
						</Alert>
					)}
				</ModalBody>
				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						data-style={EXPAND_LEFT}
						onClick={handleSubmit}
						type="button"
					>
						<i className="fa fa-save" /> Enregistrer{" "}
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

const formName = "TpltBlocFormPopup";

const rxf = reduxForm({ form: formName })(FormPopup);

FormPopup.defaultProps = {
	currentValues: {},
};
FormPopup.propTypes = {
	currentValues: PropTypes.shape({}),
};

export const mapStateToProps = state => ({
	currentValues: getFormValues(formName)(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({ change }, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(rxf);
