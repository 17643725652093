import React, {Component} from 'react';
import {Form} from 'reactstrap';
import {Field, reduxForm} from 'redux-form'
import {ModalBody, ModalFooter} from 'reactstrap';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';

import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {getLocal} from "../../reducers/params";
import {WhFile, WhFieldSelect, WhValidator, WhFieldCkEditor, WhFieldTxt} from "../../components/Form/whFields";
import {
    getCmsStatus,
} from "../../reducers/params";


const upper = value => value && value.charAt(0).toUpperCase() + value.slice(1)

class TagForm extends Component {
    state = {
        open: false
    };


    render = () => {
        const { handleSubmit, submitting, cmsStatus, locale} = this.props;

        const style = locale ? locale == 'en' ? {
            backgroundSize: "20px",
            backgroundImage: `url("/img/en.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        } : {
            backgroundSize: "20px",
            backgroundImage: `url("/img/fr.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        } : {
            backgroundSize: "20px",
            backgroundImage: `url("/img/fr.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        };

        return (
            <Form onSubmit={handleSubmit} className="form-horizontal">

                <ModalBody>

                    <Field
                        label="Nom :"
                        name="name"
                        component={WhFieldTxt}
                        type="text"
                        style={style}
                        validate={[WhValidator.required]}
                        normalize={upper}
                    />

                    <Field
                        label="Status :"
                        name="status"
						component={WhFieldSelect}
                        type="select"
                        style={style}
                        validate={[WhValidator.required]}
                        options={[
							{ value: "draft", name: "Brouillon" },
							{ value: "published", name: "Publié" },
						]}

                    />

                
                </ModalBody>

                <ModalFooter>

                    <LaddaButton
                        className="btn btn-success btn-ladda"
                        loading={submitting}
                        data-style={EXPAND_LEFT}
                        type={'submit'}
                    >
                        <i className="fa fa-save"> </i>
                        &nbsp;Enregistrer
                    </LaddaButton>

                </ModalFooter>

            </Form>

        );

    }
}

TagForm.propTypes = {
    error: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    local: PropTypes.string
};
TagForm.defaultProps = {
    error: "",
};

const mapStateToProps = (state) => ({
    local: getLocal(state),
    cmsStatus: getCmsStatus(state),
});

TagForm = connect(
    mapStateToProps
)(TagForm);

export default reduxForm({
    form: 'TagForm'
})(TagForm);