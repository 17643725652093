import React, {Component} from 'react';
import {Field, reduxForm, FormSection, FieldArray} from 'redux-form'
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';
import classnames from "classnames";
import {connect } from 'react-redux';
import PropTypes from "prop-types";
import {getLocal, getSeoRobots} from "../../reducers/params";
import {
    WhFile,
    WhFieldSelect,
    WhValidator,
    WhFieldCkEditor,
    WhFieldTxt,
    WhFieldSelectAsync
} from "../../components/Form/whFields";
import {
    getCmsStatus,
} from "../../reducers/params";
import FormSeo from "../Cms/Seo/Form";
import {
	Card,
	CardHeader,
	Form,
	TabContent,
	TabPane,
	Nav,
	NavItem,
    NavLink,
    ModalBody, 
    ModalFooter
} from "reactstrap";
import * as subUniverseService from "../../services/universe/subuniverse";
import * as jobService from "../../services/universe/job";
import SourcesField from "../Universe/SourcesField";



class BranchForm extends Component {
    state = {
        open: false,
        activeTab: "1",

    };

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

    loadSubUniverses = async input => {
        const data = await subUniverseService.search({
            filtered: [{id: "title", value: input}, {id: "exists[universe]", value: '0'}, {id: "exists[activitySector]", value: '0'}],
        });

        const lst = data["hydra:member"].map(el => ({
            value: el.id,
            id: el.id,
            label: `${el.title}`,
        }));

        // ajout des sous univers deja associe pour les rajouter si on les enleve, car le filtre exists les retire de la liste
        const {initialValues} = this.props;
        Array.prototype.push.apply(lst, initialValues.subUniverses);

        return {options: lst};
    };

    loadJobs = async input => {
        const data = await jobService.search({
            filtered: [{id: "title", value: input}, {id: "pagination", value: '0'}],
        });

        const lst = data["hydra:member"].map(el => ({
            value: el.id,
            id: el.id,
            label: `${el.title}`,
        }));

        return {options: lst};
    };

    render = () => {
        const { handleSubmit, submitting, cmsStatus, locale, seoRobots} = this.props;

        const style = locale ? locale == 'en' ? {
            backgroundSize: "20px",
            backgroundImage: `url("/img/en.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        } : {
            backgroundSize: "20px",
            backgroundImage: `url("/img/fr.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        } : {
            backgroundSize: "20px",
            backgroundImage: `url("/img/fr.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        };

        return (

            <Form onSubmit={handleSubmit} className="form-horizontal">

                <ModalBody>
                    <Card>
                    <CardHeader>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            "1",
                                    })}
                                    onClick={() => {
                                        this.toggle("1");
                                    }}
                                >
                                    Contenu
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            "5",
                                    })}
                                    onClick={() => {
                                        this.toggle("5");
                                    }}
                                >
                                    Sources
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            "2",
                                    })}
                                    onClick={() => {
                                        this.toggle("2");
                                    }}
                                >
                                    SEO
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            "4",
                                    })}
                                    onClick={() => {
                                        this.toggle("4");
                                    }}
                                >
                                    Push
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            "3",
                                    })}
                                    onClick={() => {
                                        this.toggle("3");
                                    }}
                                >
                                    Univers
                                </NavLink>
                            </NavItem>

                        </Nav>

                    </CardHeader>

                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Field
                                    label="Nom *:"
                                    name="name"
                                    component={WhFieldTxt}
                                    type="text"
                                    style={style}
                                    required
                                    validate={[WhValidator.required]}
                                />

                                <Field
                                    label="Slug *:"
                                    name="slug"
                                    component={WhFieldTxt}
                                    required
                                    validate={[WhValidator.required]}
                                    type="text"
                                    style={style}
                                />

                                <Field
                                    label="Sous Titre :"
                                    name="subTitle"
                                    component={WhFieldTxt}
                                    type="text"
                                    style={style}
                                    placeholder={'Les enjeux du secteur ..'}
                                    validate={[WhValidator.required]}
                                />

                                <Field
                                    label="Description :"
                                    name="description"
                                    component={WhFieldCkEditor}
                                    type="textarea"
                                    style={style}
                                />

                                <Field
                                    label="Statut de publication :"
                                    name="status"
                                    required
                                    validate={[WhValidator.required]}
                                    component={WhFieldSelect}
                                    type="select"
                                    options={cmsStatus}
                                    style={style}
                                />

                                <Field
                                    label="Description sur la home :"
                                    name="textHome"
                                    component={WhFieldCkEditor}
                                    type="textarea"
                                    style={style}
                                />

                                <Field
                                    label="Image"
                                    name="image"
                                    component={WhFile}
                                    apiUrl={true}
                                    type="text"
                                />

                                <Field
                                    label="Bannière"
                                    name="banner"
                                    component={WhFile}
                                    apiUrl={true}
                                    type="text"
                                />

                            </TabPane>

                            <TabPane tabId="2">
                                <FormSection name="seo">
                                    <FormSeo
                                        seoRobots={seoRobots}
                                    />
                                </FormSection>
                            </TabPane>

                            <TabPane tabId="3">
                                <Field
                                    label="Gestion des Sous-Secteurs :"
                                    name="titleSubUniverses"
                                    component={WhFieldTxt}
                                    type="text"
                                    style={style}
                                    placeholder={'Les branches du secteur ..'}
                                />

                                {/*<Field*/}
                                {/*    label="Sous Univers"*/}
                                {/*    name="subUniverses"*/}
                                {/*    component={WhFieldSelectAsync}*/}
                                {/*    type="select"*/}
                                {/*    loadOptions={this.loadSubUniverses}*/}
                                {/*    multi*/}
                                {/*/>*/}

                                <Field
                                    label="Titre Métiers :"
                                    name="titleJobs"
                                    component={WhFieldTxt}
                                    type="text"
                                    style={style}
                                    placeholder={'Les métiers du secteur ..'}
                                />

                                {/*<Field*/}
                                {/*    label="Métiers"*/}
                                {/*    name="jobs"*/}
                                {/*    component={WhFieldSelectAsync}*/}
                                {/*    type="select"*/}
                                {/*    loadOptions={this.loadJobs}*/}
                                {/*    multi*/}
                                {/*/>*/}
                            </TabPane>

                            <TabPane tabId="4">
                                <Field
                                    label="Afficher sur l'accueil ?"
                                    name="pushHome"
                                    component={WhFieldSelect}
                                    options={[
                                        {
                                            value: "false",
                                            name: "Non"
                                        },
                                        {
                                            value: "true",
                                            name: "Oui"
                                        },
                                    ]}
                                    required
                                    style={style}
                                />

                                <Field
                                    label="Afficher sur la page concept ?"
                                    name="pushConcept"
                                    component={WhFieldSelect}
                                    options={[
                                        {
                                            value: "false",
                                            name: "Non"
                                        },
                                        {
                                            value: "true",
                                            name: "Oui"
                                        },
                                    ]}
                                    required
                                    style={style}
                                />

                                <Field
                                    label="Afficher dans le menu ?"
                                    name="pushMenu"
                                    component={WhFieldSelect}
                                    options={[
                                        {
                                            value: "false",
                                            name: "Non"
                                        },
                                        {
                                            value: "true",
                                            name: "Oui"
                                        },
                                    ]}
                                    required
                                    style={style}
                                />

                                <Field
                                    label="Afficher dans les filtres de recherches ?"
                                    name="displayOnFilters"
                                    component={WhFieldSelect}
                                    options={[
                                        {
                                            value: "false",
                                            name: "Non"
                                        },
                                        {
                                            value: "true",
                                            name: "Oui"
                                        },
                                    ]}
                                    required
                                    style={style}
                                />

                            </TabPane>

                            <TabPane tabId="5">
                                <h6>Sources</h6>
                                <FieldArray
                                    name={`sourcesArray`}
                                    component={SourcesField}
                                />
                            </TabPane>

                        </TabContent>
                    </Card>

                </ModalBody>

                <ModalFooter>

                    <LaddaButton
                        className="btn btn-success btn-ladda"
                        loading={submitting}
                        data-style={EXPAND_LEFT}
                        type={'submit'}
                    >
                        <i className="fa fa-save"> </i>
                        &nbsp;Enregistrer
                    </LaddaButton>

                </ModalFooter>

            </Form>

        );

    }
}

BranchForm.propTypes = {
    error: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    local: PropTypes.string,
	seoRobots: PropTypes.array,


};
BranchForm.defaultProps = {
    error: "",
    seoRobots: [],

};

const mapStateToProps = (state) => ({
    local: getLocal(state),
    cmsStatus: getCmsStatus(state),
    seoRobots: getSeoRobots(state),

});

BranchForm = connect(
    mapStateToProps
)(BranchForm);

export default reduxForm({
    form: 'BranchForm'
})(BranchForm);