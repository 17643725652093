import React from "react";
import CKEditor from "ckeditor4-react";
import { change } from "redux-form";
import ApiConfig from "../../../constants/ApiConfig";
import {Col, FormGroup, Label} from "reactstrap";

class FieldCkEditor extends React.Component {
	onChange = evt => {
		try {
			const data = evt.editor.getData();
			this.props.meta.dispatch(
				change(this.props.meta.form, this.props.input.name, data),
			);
		} catch (error) {}
	};

	render() {
		const { input, label, required } = this.props;
		CKEditor.editorUrl = ApiConfig.ckeditorInstance;
		return (
			<FormGroup row>
				<Col lg="12">
					{label && <Label>{label}</Label>}
					{required && (
						<span className="text-danger font-weight-bold">*</span>
					)}
				</Col>
				<Col lg="12">
					<CKEditor
						data={input.value}
						config={{
							startupOutlineBlocks: "true",
							autoParagraph: false,
							toolbar: [
								[
									"Heading",
									"Bold",
									"BulletedList",
									"Italic",
									"Link",
									"Format",
									"Styles",
									// "Source",
									"Underline",
									"Sourcedialog",
									"Showblock",
									"Table",
									"HorizontalRule",
									"JustifyLeft",
									"JustifyCenter",
									"JustifyRight",
									"ShowBlocks",
									"Glyphicons",
								],
							],
						}}
						onChange={this.onChange}
					/>
				</Col>
			</FormGroup>
		);
	}
}

export default FieldCkEditor;
