import React from "react";

import {
    Field, FieldArray, FormSection,
    reduxForm,

} from "redux-form";
import {
    Card,
    CardHeader,
    CardBody,
    Form, NavItem, NavLink, Nav, TabContent, TabPane, Alert, ModalBody,

} from "reactstrap";

import LaddaButton, {EXPAND_LEFT} from "react-ladda";
import classnames from "classnames";

import {
    WhFieldTxt,
    WhValidator,
    WhFieldSelectAsync,
    WhFieldCkEditor, WhFile,
} from "../../../components/Form/whFields";
import * as tagsService from "../../../services/tag";
import * as subUniverseService from "../../../services/universe/subuniverse";
import * as jobService from "../../../services/universe/job";
import SourcesField from "../SourcesField";
import FormSeo from "../../Cms/Seo/Form";
import {getSeoRobots} from "../../../reducers/params";
import {connect} from "react-redux";
import ApiConfig from "../../../constants/ApiConfig";

class UniversForm extends React.Component {
    state = {
        activeTab: "1",
    };

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    loadTags = input => {
        return tagsService
            .search({
                filtered: [
                    {id: "name", value: input},
                    {id: "exists[universe]", value: '0'},
                    {id: "exists[subUniverse]", value: '0'},
                    {id: "exists[job]", value: '0'},
                    {id: "pagination", value: '0'},
                    {id: "status", value: 'published'}
                ]
            })
            .then(data => {
                const lst = data["hydra:member"].map((el, index) => {
                    return {id: el.id, label: `${el.name}`};
                });
                return {options: lst};
            });
    };

    loadSubUniverses = async input => {
        const data = await subUniverseService.search({
            filtered: [{id: "title", value: input}, {id: "exists[universe]", value: '0'}, {id: "exists[activitySector]", value: '0'}],
        });

        const lst = data["hydra:member"].map(el => ({
            value: el.id,
            id: el.id,
            label: `${el.title}`,
        }));

        // ajout des sous univers deja associe pour les rajouter si on les enleve, car le filtre exists les retire de la liste
        const {initialValues} = this.props;
        Array.prototype.push.apply(lst, initialValues.subUniverses);

        return {options: lst};
    };

    loadJobs = async input => {
        const data = await jobService.search({
            filtered: [{id: "title", value: input}, {id: "pagination", value: '0'}],
        });

        const lst = data["hydra:member"].map(el => ({
            value: el.id,
            id: el.id,
            label: `${el.title}`,
        }));

        return {options: lst};
    };

    render = () => {
        const {
            handleSubmit,
            locale,
            initialValues,
            submitting,
            seoRobots,
            error
        } = this.props;

        const style = locale ? locale == 'en' ? {
            backgroundSize: "20px",
            backgroundImage: `url("/img/en.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        } : {
            backgroundSize: "20px",
            backgroundImage: `url("/img/fr.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        } : {
            backgroundSize: "20px",
            backgroundImage: `url("/img/fr.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        };

        const creation = Object.keys(initialValues).length === 0;

        return (
            <Form onSubmit={handleSubmit}>
                <Card>
                    <CardHeader>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            "1",
                                    })}
                                    onClick={() => {
                                        this.toggle("1");
                                    }}
                                >
                                    Contenu
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            "2",
                                    })}
                                    onClick={() => {
                                        this.toggle("2");
                                    }}
                                >
                                    SEO
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <div className="card-actions">
                            {!creation && (
                                <a
                                    href={`${ApiConfig.url}/fr/univers/${
                                        initialValues.id
                                    }/${initialValues.slug}/`}
                                    target="_blank"
                                    className="btn btn-block btn-success btn-ld"
                                >
                                    Voir
                                </a>
                            )}
                            <LaddaButton
                                className="btn btn-success btn-ladda"
                                loading={submitting}
                                data-style={EXPAND_LEFT}
                                type="submit"
                            >
                                <i className="fa fa-save"/>
                                &nbsp;Enregistrer
                            </LaddaButton>
                        </div>
                    </CardHeader>

                    <CardBody>

                        {error && (
                            <Alert color="danger">
                                <div dangerouslySetInnerHTML={{ __html: error }} />
                            </Alert>
                        )}

                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Field
                                    label="Titre :"
                                    name="title"
                                    component={WhFieldTxt}
                                    type="text"
                                    style={style}
                                    validate={[WhValidator.required]}
                                />

                                <Field
                                    label="Sous Titre :"
                                    name="subTitle"
                                    component={WhFieldTxt}
                                    type="text"
                                    style={style}
                                    placeholder={'Les enjeux du secteur ..'}
                                    validate={[WhValidator.required]}
                                />

                                <Field
                                    label="Description :"
                                    name="description"
                                    component={WhFieldCkEditor}
                                    type="textarea"
                                    style={style}
                                />

                                <Field
                                    label="Titre Sous Secteur :"
                                    name="titleSubUniverse"
                                    component={WhFieldTxt}
                                    type="text"
                                    style={style}
                                    placeholder={'Les branches du secteur ..'}
                                    validate={[WhValidator.required]}
                                />

                                {/*<Field*/}
                                {/*    label="Sous Univers"*/}
                                {/*    name="subUniverses"*/}
                                {/*    component={WhFieldSelectAsync}*/}
                                {/*    type="select"*/}
                                {/*    loadOptions={this.loadSubUniverses}*/}
                                {/*    multi*/}
                                {/*/>*/}

                                <Field
                                    label="Titre Métiers :"
                                    name="titleJobs"
                                    component={WhFieldTxt}
                                    type="text"
                                    style={style}
                                    placeholder={'Les métiers du secteur ..'}
                                    validate={[WhValidator.required]}
                                />

                                {/*<Field*/}
                                {/*    label="Métiers"*/}
                                {/*    name="jobs"*/}
                                {/*    component={WhFieldSelectAsync}*/}
                                {/*    type="select"*/}
                                {/*    loadOptions={this.loadJobs}*/}
                                {/*    multi*/}
                                {/*/>*/}

                                <Field
                                    label="Bannière"
                                    name="banner"
                                    component={WhFile}
                                    apiUrl={true}
                                    type="text"
                                />

                                <FieldArray
                                    name={`sourcesArray`}
                                    component={SourcesField}
                                />

                                {creation && (
                                    <Field
                                        label="Tag lié :"
                                        name="tag"
                                        component={WhFieldSelectAsync}
                                        type="select"
                                        loadOptions={this.loadTags}
                                    />
                                )}
                            </TabPane>

                            <TabPane tabId="2">
                                <FormSection name="seo">
                                    <FormSeo
                                        seoRobots={seoRobots}
                                    />
                                </FormSection>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Form>
        );
    };
}

const mapStateToProps = (state) => ({
    seoRobots: getSeoRobots(state),
});

UniversForm = connect(
    mapStateToProps
)(UniversForm);

export default reduxForm({
    form: 'UniversForm'
})(UniversForm);
