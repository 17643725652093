/**
 * HOME 01
 */

const home01Container = {
	//Cette partie à externaliser en liste de blocs (issue de SF ?)
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Titre ",
		fields: {
			background: {
				admin: {
					template: "BlocMedia",
					type: "tplt-picture",
				},
			},
			title: {
				admin: {
					template: "BlocTitle",
					content:
						'<h1 class="text-center size-huge weight-bold lh-10"> Les meilleures missions<br>pour les freelances bâtisseurs<br>des nouvelles technologies</h1>',
				},
			},
		},
	},
};

export const column = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Colonne",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content: '<span class="weight-light size-big">Le choix de la différence !</span>',
				},
			},
			subtitle: {
				admin: {
					template: "BlocText1",
					content: '<span class="weight-bold size-big mt-10">Pourquoi choisir Castor Network ?</span>',
				},
			},
		},
	},
};

export const fourColumns = {
	admin: {
		template: "BlocCollection",
		title: "Bloc quatres colonnes",
		type: "tplt-slider-horizontal",
		add: true,
		editable: true,
		defaultTemplates: [column],
	},
};

const home01 = {
	//Cette partie à externaliser en liste de blocs (issue de SF ?)
	tplt: "/home/home01.html.twig",
	admin: {
		template: "BlocCollection",
		title: "Bloc 1",
		editable: true,
	},
	contents: [home01Container, fourColumns],
};

/**
 * HOME 02
 */
export const home02Container = {
	admin: {
		template: "BlocContainer",
		title: "Bloc 2 en-tête",
		type: "tplt-paragraph",
		fields: {
			background: {
				admin: {
					template: "BlocMedia",
					type: "tplt-picture",
				},
			},
			paragraphe: {
				admin: {
					template: "BlocText1",
					type: "tplt-paragraph",
					content: '<h2 class="size-title weight-light">Le choix de la différence !</h2> <h2 class="size-title weight-bold">Pourquoi choisir Castor Network ?</h2>',
				},
			},
			button: {
				admin: {
					template: "BlocText1",
					type: "tplt-paragraph",
					content: "Découvrir nos engagements"
				}
			}
		},
	},
};

export const textWithIcon = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Element",
		fields: {
			icon: {
				admin: {
					template: "BlocText1",
					content: '<div class="homeColumns-icon"> <span class="icon-conseil"></span> </div>',
				},
			},
			title: {
				admin: {
					template: "BlocText1",
					content: ' <span class="weight-black size-title">Conseil</span>',
				},
			},
			subtitle: {
				admin: {
					template: "BlocText1",
					content: '<span class="weight-light size-big mt-5">Accompagnement personnalisé</span>',
				},
			},
		},
	},
};

export const fourTextWithIcons = {
	admin: {
		template: "BlocCollection",
		title: "Bloc quatres colonnes",
		type: "tplt-slider-horizontal",
		add: true,
		editable: true,
		defaultTemplates: [textWithIcon],
	},
};

const home02 = {
	//Cette partie à externaliser en liste de blocs (issue de SF ?)
	tplt: "/home/home02.html.twig",
	admin: {
		template: "BlocCollection",
		title: "Le choix de la différence !",
		editable: true,
	},
	contents: [home02Container, fourTextWithIcons],
};

/**
 * HOME 03
 */

const home03 = {
	//Cette partie à externaliser en liste de blocs (issue de SF ?)
	tplt: "/home/home03.html.twig",
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Freelance ou entreprise",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content:
						'<div class="flex-selfStart text-left sectionContent sectionContent--gridRow"> <h2 class="size-title weight-light">Freelance ou entreprise ?</h2> <h2 class="size-title weight-bold">Découvrez leurs témoignages !</h2> </div>',
				},
			},
		},
	},
};

/**
 * HOME 04
 */
const home04 = {
	//Cette partie à externaliser en liste de blocs (issue de SF ?)
	tplt: "/home/home04.html.twig",
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Trouvez la mission !",
		fields: {
			title: {
				admin: {
					template: "BlocText1",
					content:
						' <div class="teamPresentation w-70"> <h2 class="size-title weight-light">Trouvez la mission !</h2> <h2 class="size-title weight-bold">Nos missions freelance par univers</h2> </div>',
				},
			},
			button: {
				admin: {
					template: "BlocTitle",
					content: 'Trouvez votre mission'
				}
			}
		},
	},
};
/**
 * HOME 05
 */
const Home05Media = {
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "Photo",
		fields: {
			media: {
				admin: {
					template: "BlocMedia",
				},
			},
		},
	},
};

const home05 = {
	//Cette partie à externaliser en liste de blocs (issue de SF ?)
	tplt: "/home/home05.html.twig",
	admin: {
		editable: true,
		add: false,
		template: "BlocContainer",
		title: "An Alten Company",
		fields: {
			paragraphe: {
				admin: {
					template: "BlocText1",
					content:
						' <div class="sectionContent sectionContent--gridContent flex-justifyCenter flex-alignCenter"> <h1 class="text-center size-title weight-bold"><span class="weight-light">An Alten company,</span> <br>une aventure qui commence !</h1> <p class="text-center size-subtitle weight-light w-70 mt-30">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p></div>',
				},
			},
		},
	},
};
/**
 * EXPORT
 */
export default {
	template: "home",
	name: "Accueil",
	editable: true,
	add: false,
	seo: {
		robots: "index,follow",
	},
	builder: [home01, home02, home04, home05],
};
