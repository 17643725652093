import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	Field,
	FormSection,
	reduxForm,
	FieldArray,
	change,
	getFormValues,
} from "redux-form";
import classnames from "classnames";
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Form,
	Alert,
	Row,
	Col,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
} from "reactstrap";

import { bindActionCreators } from "redux";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import ApiConfig from "../../../constants/ApiConfig";
import Save from "../../../components/Save";
import * as pageService from "../../../services/cmsPage";
import {
	getCmsStatus,
	getSeoRobots,
	getCmsTemplates,
} from "../../../reducers/params";
import FormSeo from "../Seo/Form";
import {
	WhFieldTxt,
	WhFile,
	WhFieldCheckbox,
	WhValidator,
	WhFieldSelect,
	WhFieldSelectAsync,
	WhFieldBuilder, WhParseInt,
} from "../../../components/Form/whFields";
import {getUser} from "../../Authentication/redux";

class FormEdit extends React.Component {
	state = {
		activeTab: "1",
	};

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

	loadParents = input => {
		return pageService
			.search({ filtered: [{ id: "title", value: input }] })
			.then(data => {
				const lst = data["hydra:member"].filter((el) => el.id !== parseInt(this.props.pageId.id)).map((el, index) => {
					return { id: el.id, label: `${el.title}` };
				});
				return { options: lst };
			});
	};

	render = () => {
		const {
			error,
			handleSubmit,
			submitting,
			change,
			seoRobots,
			currentValues,
			cmsStatus,
		} = this.props;

		const urlParams = new URLSearchParams(window.location.search);
		const locale = urlParams.get('locale');

		const style = locale ? locale == 'en' ? {
			backgroundSize: "20px",
			backgroundImage: `url("/img/en.png")`,
			backgroundRepeat: " no-repeat ",
			backgroundPosition: "right top",
		} : {
			backgroundSize: "20px",
			backgroundImage: `url("/img/fr.png")`,
			backgroundRepeat: " no-repeat ",
			backgroundPosition: "right top",
		} : {
			backgroundSize: "20px",
			backgroundImage: `url("/img/fr.png")`,
			backgroundRepeat: " no-repeat ",
			backgroundPosition: "right top",
		};

		return (
			<Form onSubmit={handleSubmit}>
				<Row>
					<Col sm="8" xs={12} className="form-vertical">
						<Card>
							<CardHeader>
								<Nav tabs>
									<NavItem>
										<NavLink
											className={classnames({
												active:
													this.state.activeTab ===
													"1",
											})}
											onClick={() => {
												this.toggle("1");
											}}
										>
											Contenu
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
												active:
													this.state.activeTab ===
													"2",
											})}
											onClick={() => {
												this.toggle("2");
											}}
										>
											SEO
										</NavLink>
									</NavItem>
								</Nav>
								<Save
									data={currentValues}
									setHistory={change}
									type="page"
								/>
							</CardHeader>

							<TabContent activeTab={this.state.activeTab}>
								<TabPane tabId="1">
									<Field
										label={false}
										name="title"
										component={WhFieldTxt}
										type="text"
										placeholder="Titre de la page"
										bsSize="lg"
										style={style}
									/>

									<Field
										label={false}
										name="slug"
										component={WhFieldTxt}
										validate={[WhValidator.required]}
										type="text"
										placeholder="Nom dans l'url"
										style={style}
									/>

									<div className="content">
										<FieldArray
											name='builder'
											component={WhFieldBuilder}
										/>
									</div>
								</TabPane>
								<TabPane tabId="2">
									<FormSection name="seo">
										<FormSeo
											seoRobots={seoRobots}
										/>
									</FormSection>
								</TabPane>
							</TabContent>
						</Card>
					</Col>
					<Col sm="4" xs={12}>
						<Card>
							<CardHeader>
								<i className="fa fa-align-justify" /> Edition
							</CardHeader>

							<CardBody>
								<div className="form-vertical">
									<Field
										label="Type de page :"
										name="template"
										disabled={true}
										component={WhFieldTxt}
										type="text"
									/>
								</div>
								<Field
									label="Statut de publication :"
									name="status"
									validate={[WhValidator.required]}
									nullValue="Choisir un statut"
									required={false}
									component={WhFieldSelect}
									type="select"
									options={cmsStatus}
								/>
								<div className="form-vertical">

									{
										this.props.user.roles.includes('ROLE_ADMIN') && (
											<Field
												label="Page parente :"
												name="parent"
												component={WhFieldSelectAsync}
												type="select"
												loadOptions={this.loadParents}
											/>
										)
									}


									<Field
										label="Score : "
										parse={WhParseInt}
										name="score"
										component={WhFieldTxt}
										type="number"
									/>

									<Field
										label="Résumé : "
										name="summary"
										component={WhFieldTxt}
										type="textarea"

									/>
								</div>

								{error &&
								<Alert color="danger">
									<div dangerouslySetInnerHTML={{__html: error}}/>
								</Alert>
								}
							</CardBody>

							<CardFooter style={{ display: "flex" }}>
								<LaddaButton
									className="btn btn-primary btn-ladda"
									loading={submitting}
									data-style={EXPAND_LEFT}
									type="submit"
									style={{ flex: 1 }}
								>
									<i className="fa fa-save" /> Enregistrer{" "}
								</LaddaButton>
							</CardFooter>
						</Card>

						{currentValues.id && (
							<Card>
								<a
									href={`${ApiConfig.url}/api/redirect/page/${
										currentValues.id
									}`}
									target="_blank"
									className="btn btn-block btn-success btn-ld"
								>
									Voir
								</a>
							</Card>
						)}

						<Field
							withHeader={true}
							label="Vignette"
							name="vignette"
							component={WhFile}
						/>
					</Col>
				</Row>
			</Form>
		);
	};
}

const formName = "FormEdit";

const rxf = reduxForm({ form: formName })(FormEdit);

FormEdit.defaultProps = {
	error: "",
	categories: [],
	cmsStatus: [],
	cmsTplts: [],
	seoRobots: [],
	currentValues: {},
};
FormEdit.propTypes = {
	error: PropTypes.string,
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	currentValues: PropTypes.shape({}),
	cmsStatus: PropTypes.array,
	cmsTplts: PropTypes.array,
	seoRobots: PropTypes.array,
};

export const mapStateToProps = state => ({
	currentValues: getFormValues(formName)(state),
	cmsStatus: getCmsStatus(state),
	cmsTplts: getCmsTemplates(state),
	seoRobots: getSeoRobots(state),
	user: getUser(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({ change }, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(rxf);
