import React, {Component} from 'react';
import {connect} from 'react-redux';

class SidebarHeader extends Component {

    render() {

        return (
            <div className="sidebar-header">
                <div>
                    <a href="javascript:void(0);" onClick={this.handleClick}>MON DASHBOARD <i className={'fa fa-home'} /></a>
                </div>
            </div>
        );

    }

}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SidebarHeader);