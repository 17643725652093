import {
	containerParagraphe1,
	containerParagraphe2,
	picture,
	video,
	foliosLeftTextRight,
	sliderAstuce,
	sliderFlap01,
	twoColumns,
	threeColumns,
	fourColumns,
	sliderNavLeftPhotoTxt,
} from "../block";

import sliderPhotoVideo from "../block/sliderPhotoVideo";

export default {
	template: "page",
	name: "Page",
	default: true,
	seo: {
		robots: "index,follow",
	},
	builder: [
		{
			tplt: "/page/page/default.html.twig",
			admin: {
				editable: false,
				add: false,
				template: "BlocContainer",
				title: "Introduction",
				fields: {
					subTitle: {
						admin: {
							template: "BlocText1",
							content:
								' Trouvez les freelances parfaits pour vos missions ',
						},
					},
					content: {
						admin: {
							template: "BlocText1",
							content: " <div class=\"roundBlock-content size-small weight-light lh-16\"> <p class=\"weight-bold size-big\">Who we are</p><p class=\"mt-30\">En collaboration avec l’équipe créative et l’équipe commerciale, tu interviendras sur l’ensemble des projets du studio. Ta mission consistera à travailler sur des formats publicitaires digitaux, de la conception à la réalisation graphique, répondant aux besoins de nos clients et prospects.</p><p class=\"weight-bold size-big mt-20\">What you'll do</p><p class=\"mt-30\">Sur la base de briefs, porter la réflexion sur des concepts créatifs, être force de proposition sur le design, l’ergonomie et la technique<br>Assurer la production de maquettes en fonction des différentes problématiques et des clients.<br></p><p class=\"weight-bold size-big mt-20\">Who you are</p><p class=\"mt-30\">Tu es créatif(ve) tout en sachant canaliser cette créativité pour respecter les codes et impératifs propres au e-commerce.<br>Tu comprends vite, travaille efficacement, rapidement et sait faire preuve de rigueur<br>Tu as déjà effectué un ou plusieurs stages en agence<br></p></div>",
						},
					},
				},
			},
		}
	],
};
