import React, { Component } from "react";
import { Card, CardHeader, Button } from "reactstrap";

import WHTable from "../../../components/WHTable";
import * as jobService from "../../../services/universe/job";
import ApiConfig from "../../../constants/ApiConfig";

class Jobs extends Component {
	state = {
		data: [],
		pages: null,
		loaded: true,
	};

	showdetails = (id, locale = null) => {
		locale ? this.props.history.push(`/jobs/edit/${id}?locale=${locale}`) : this.props.history.push(`/jobs/edit/${id}`);
	};

	columns() {
		const viewUrl = ApiConfig.url + "/fr/metier/";

		return [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						<Button
							color="primary"
							onClick={() => this.showdetails(row.value)}
						>
							<i className="fa fa-edit" /> <img style={{width: '20px'}} src="img/fr.png" alt=""/>
						</Button>
						<Button
							color="info"
							onClick={() => this.showdetails(row.value, 'en')}
						>
							<i className="fa fa-edit" /> <img style={{width: '20px'}} src="img/en.png" alt=""/>
						</Button>
						<a
							className="btn btn-info"
							target="_blank"
							href={viewUrl + row.value + "/" + row.original.slug + '/'}
						>
							<i className="fa fa-eye" />
						</a>
						<Button
							color="danger"
							onClick={() => this.remove(row.value)}
						>
							<i className="fa fa-trash" />
						</Button>
					</div>
				),
			},
			{
				Header: "Titre",
				accessor: "title",
			},
			{
				Header: "Tag",
				accessor: "tag.name",
			},
		];
	}

	load = state => {
		this.setState({
			loaded: false,
			filters: state,
		});

		return jobService.search(state).then(data => {
			this.setState({
				data: data["hydra:member"],
				pages: Math.ceil(
					data["hydra:totalItems"] / state.pageSize,
				),
				loaded: true,
			});
		});
	};

	remove = toDelete => {
		if (
			window.confirm("Êtes-vous sûr de vouloir supprimer ce métier ?")
		) {
			this.setState({
				loaded: false,
			});

			jobService
				.remove(toDelete)
				.then(() => {
					const lst = [...this.state.data];

					const index = lst.findIndex(el => el.id === toDelete);

					if (index > -1) {
						lst.splice(index, 1);

						this.setState({
							data: lst,
							loaded: true,
						});
					}
				})
				.catch(() => {
					alert(
						"Il existe des dépendances sur ce métier, il ne peut donc être supprimé",
					);
				});
		}
	};

	newData = data => {
		const lst = [...this.state.data];

		const index = lst.findIndex(el => el.id === data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({
			data: lst,
		});
	};

	render = () => {
		const { data, pages } = this.state;

		return (
			<div className="animated fadeIn">
				<h1>Pages Métiers</h1>
				<Card>
					<CardHeader>
						<i className="fa fa-align-justify" />
						&nbsp;Gestion des pages métiers
						<div className="card-actions">
							<button
								onClick={() =>
									this.props.history.push("/jobs/creer")
								}
								className="btn btn-primary"
							>
								<i className={"fa fa-plus"} />
								&nbsp;Nouveau
							</button>
						</div>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	};
}


export default Jobs;
