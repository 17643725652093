import headerScroll from "../block/headerScroll";

export const textScrollLine = {
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Element",
        fields: {
            background: {
                admin: {
                    template: "BlocMedia",
                    type: "tplt-picture",
                },
            },
            title: {
                admin: {
                    template: "BlocTitle",
                    content: ' <h2 class=" size-title weight-bold">Je rejoins la communauté !</h2> ',
                },
            },
            description: {
                admin: {
                    template: "BlocText1",
                    content: ' <p class=" size-medium weight-bold mt-15 lh-20">Devenez acteur de votre destin en vous inscrivant à la plateforme. Vous pourrez ensuite compléter vos expertises et vos souhaits de mission (ex : secteur d’activité, mobilité géographique…).</p> '
                }
            },
            secondTitle: {
                admin: {
                    template: "BlocTitle",
                    content: ' <h2 class=" size-title weight-bold">Titre deux !</h2> ',
                },
            },
            secondDescription: {
                admin: {
                    template: "BlocText1",
                    content: ' <p class=" size-medium weight-bold mt-15 lh-20">Description deux.</p> '
                }
            }
        },
    },
};

export const scrollLine = {
    tplt: "/page/howItWorks/scroll-line.html.twig",
    admin: {
        template: "BlocCollection",
        title: "Scroll Line",
        type: "tplt-slider-horizontal",
        add: true,
        editable: true,
        defaultTemplates: [textScrollLine],
    },
};

/**
 * EXPORT
 */
export default {
    template: "howItWorks",
    name: "Comment ça marche",
    editable: true,
    add: false,
    seo: {
        robots: "index,follow",
    },
    builder: [headerScroll, scrollLine],
};
