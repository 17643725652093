import {fetch} from '../fetch';
import configApi from "../../constants/ApiConfig";

const endPoints = {
    login: '/api/login_check',
    logout: '/logout',
};

export const login = (username, password) => fetch(`${configApi.url}${endPoints.login}`, 'post', {
    "email": username,
    "password": password
});

export const me = () => fetch(`${configApi.url}/api/me`, 'get');