import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import FormPopup from "./Form";

class PopupBloc extends Component {
	state = {
		open: false,
		data: {},
		index: null,
		loaded: true,
	};

	handleOpen = data => {
		if (data.defaultTemplates && data.defaultTemplates.length == 1) {
			this.submit(data.defaultTemplates[0]);
			return;
		}

		if (!data.defaultTemplates) data.defaultTemplates = {};
		this.setState({
			data: data.data,
			open: true,
			defaultTemplates: data.defaultTemplates,
			index: data.index,
		});
	};

	handleClose = () => {
		this.setState({ open: false, index: null, data: {} });
	};

	submit = data => {
		const { editBloc } = this.props;
		const { index } = this.state;

		// preventing to push a data without content (cause crash on the front size in the presentation.twig.html
		editBloc({...data, content:""}, index);
		this.setState({ open: false });
	};

	render() {
		const { data, defaultTemplates, tplts } = this.state;

		return (
			<Modal isOpen={this.state.open} fade={false} size="xs">
				<ModalHeader toggle={() => this.handleClose()}>
					Ajouter un bloc
				</ModalHeader>
				<FormPopup
					onSubmit={this.submit}
					initialValues={data}
					defaultTemplates={defaultTemplates}
				/>
			</Modal>
		);
	}
}

export default PopupBloc;
