import { fetch } from "../../utils/fetch";
import QueryBuilder from "../../components/WHTable/queryBuilder";
import configApi from "../../constants/ApiConfig";

const endPoints = "/check-maintenance";
const createEndPoints = "/create-maintenance";


export const search = data => {
  let query = QueryBuilder(data);

  return fetch(`${configApi.maintenance}${endPoints}`, "get", query);
};

export const create = data =>
  fetch(`${configApi.maintenance}${createEndPoints}`, "get", data);
