import {fetch} from '../../utils/fetch';
import QueryBuilder from '../../components/WHTable/queryBuilder';
import configApi from "../../constants/ApiConfig";

const endPoints = '/api/members';

export const search = (data) => {
    let query = QueryBuilder(data);

    return fetch(`${configApi.url}${endPoints}`, 'get', query)
};

export const view = (id, lang = null) => fetch(`${configApi.url}${endPoints}/${id}`, "get", {}, {}, lang ? {'X-LOCALE':lang} : null);

export const create = (data) => fetch(`${configApi.url}${endPoints}`, 'post', data);

export const update = (data, lang = null) => fetch(`${configApi.url}${endPoints}/${data.id}`, "put", data, {}, lang ? {'X-LOCALE':lang} : null);

export const remove = (id) => fetch(`${configApi.url}${endPoints}/${id}`, 'delete');

