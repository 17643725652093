import React, { Component } from "react";
import { Form, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Field, reduxForm, getFormValues } from "redux-form";
import {
  WhFieldTxt,
  WhFieldSelect,
  WhFieldFile, WhValidator,
} from "../../components/Form/whFields";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import { connect } from "react-redux";

class MaintenanceForm extends Component {
	test = () => {};

	render() {
		const { error, handleSubmit, submitting, currentValues, locale } = this.props;



		return (
			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>

          <Field
            label="Environnement :"
            name="env"
            component={WhFieldSelect}
            type="select"
            validate={[WhValidator.required]}
            options={[
              { value: "site", name: "Site" },
              { value: "connect", name: "Connect" },
              { value: "api", name: "Api" },
            ]}

          />

          <Field
            label="Déploiement :"
            name="deploy"
            component={WhFieldSelect}
            type="select"
            validate={[WhValidator.required]}
            options={[
              { value: true, name: "Oui" },
              { value: false, name: "Non" },
            ]}

          />


				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type="submit"
					>
						<i className="fa fa-save" />
						&nbsp;Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

const formName = "MaintenanceForm";
const ReduxFormWrapped = reduxForm({
	form: formName,
})(MaintenanceForm);

const mapState = state => ({
	currentValues: getFormValues(formName)(state),
});

const FullWrappedParametersForm = connect(mapState)(ReduxFormWrapped);

export default FullWrappedParametersForm;
