import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import FormEdit from "./Form";
import { toast } from "react-toastify";
import Loading from "../../../../components/Loading";
import * as menuItemService from "../../../../services/cmsMenuItem";

class EditPopup extends Component {
	state = {
		open: false,
		data: {},
		locale: 'fr'
	};

	handleOpen = async (data = null, parent = null, locale = 'fr') => {
		if (data != null) {
			const service = locale == 'fr' && data ? menuItemService.view : menuItemService.viewEn;

			await service(data.id).then((data) => {
				this.setState({
					data,
					loaded: true,
					parent,
					open: true,
					locale
				});

			});
		} else {
			this.setState({ loaded: true, data, open: true, parent });
		}
	};

	handleClose = () => {
		this.setState({ open: false, data: null, parent: null });
	};

	onSubmit = async data => {
		const { menu } = this.props;
		const { parent, locale } = this.state;

		if (menu) data.menu = menu["@id"];
		if (parent) data.parent = parent["@id"];
		if(data.page === '') data.page = null;

		const edit = !data.id ? menuItemService.create : locale == 'fr' ? menuItemService.update : menuItemService.updateEn;

		const newData = await edit(data);

		this.props.newData(newData);

		toast.success("Enregistrement réussi");

		this.handleClose();
	};

	render() {
		const { className } = this.props;
		const { loaded, locale } = this.state;

		return (
			<Modal isOpen={this.state.open} className={className} fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					Editer
				</ModalHeader>

				{!loaded && <Loading />}
				{loaded && (
					<FormEdit
						onSubmit={this.onSubmit}
						initialValues={this.state.data}
						locale={locale}
					/>
				)}
			</Modal>
		);
	}
}

export default EditPopup;
