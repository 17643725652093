import React, { Component } from "react";
import { Field } from "redux-form";
import {WhFieldTxt} from "../../components/Form/whFields";
import { Button, Col, Row } from 'reactstrap';

class SourcesField extends Component {

    render() {
        const { fields } = this.props;
        return (
            <div>
                <Button block color='primary' type="button" onClick={() => fields.push({})}>Ajouter une source</Button>
                <br/>
                {fields.map((lst, index) =>(
                    <ul>
                        <li key={index}>
                            <Row>
                                <Col md={1}>
                                    <a className={"btn btn-danger"} onClick={() => fields.remove(index)}>
                                        <i className='fa fa-trash'/>
                                    </a>
                                </Col>
                                <Col md={"11"}>
                                    <Field
                                        label="Lien"
                                        name={`${lst}.link`}
                                        component={WhFieldTxt}
                                        type="text"
                                    />
                                </Col>
                            </Row>
                        </li>
                    </ul>
                ) )}

            </div>
        );
    }
}

export default SourcesField;
