import React, {Component} from "react";
import WHTable from "../../components/WHTable";
import {Card, CardHeader, Button} from "reactstrap";
import CreateEditPopup from "./CreateEditPopup";
import * as tagsServices from "../../services/tag";

class TagsUniverses extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            col: [],
            pages: null,
            loaded: false,
            defaultFiltered: [],
        };

        let defaultFiltered = this.state.defaultFiltered;

        if (this.props.match.params.iswaiting === 'waiting') {
            defaultFiltered.push({id: 'status', value: "draft"});
        } else {
            defaultFiltered.push({id: 'status', value: "published"});
        }

    }

    columns() {
        return [
            {
                Header: "Nom",
                accessor: "name",
            },
            {
                Header: "Page Univers",
                accessor: "universe.title",
                Cell: row =>
                    <div>
                        {row.value && (
                            <Button
                                color="primary"
                                onClick={() => this.props.history.push(`/univers/edit/${row.original.universe.id}`)}
                            >
                                {row.value}
                            </Button>)
                        }
                    </div>
                ,
            },
            {
                Header: "Page Sous Univers",
                accessor: "subUniverse.title",
                Cell: row =>
                    <div>
                        {row.value && (
                            <Button
                                color="primary"
                                onClick={() => this.props.history.push(`/sous-univers/edit/${row.original.subUniverse.id}`)}
                            >
                                {row.value}
                            </Button>)
                        }
                    </div>
            },
            {
                Header: "Page métier",
                accessor: "job.title",
                Cell: row =>
                    <div>
                        {row.value && (
                            <Button
                                color="primary"
                                onClick={() => this.props.history.push(`/jobs/edit/${row.original.job.id}`)}
                            >
                                {row.value}
                            </Button>)
                        }
                    </div>
            },
        ];
    }

    componentDidUpdate = (prevProps) => {

        if (this.props.match.params.iswaiting !== prevProps.match.params.iswaiting) {
            let defaultFiltered = [];

            if (this.props.match.params.iswaiting === 'waiting') {
                defaultFiltered.push({id: 'status', value: "draft"});
            } else {
                defaultFiltered.push({id: 'status', value: "published"});
            }

            this.setState({
                defaultFiltered: defaultFiltered,
            });
        }
        ;

    };

    load = (state) => {
        this.setState({
            loaded: false,
        });

        state.filtered.push({id: "withUniverses", value: 'true'});

        return tagsServices.search(state).then(data => {
            this.setState(
                {
                    data: [],
                },
                () => {
                    this.setState({
                        data: data["hydra:member"],
                        pages: Math.ceil(
                            data["hydra:totalItems"] / state.pageSize,
                        ),
                        loaded: true,
                    });
                },
            );
        });
    };

    render() {
        const {data, pages} = this.state;
        return (
            <div className="animated fadeIn">
                <CreateEditPopup
                    ref={"createEditPopup"}
                    newData={this.newData}
                />
                <h1>Tags</h1>
                <Card>
                    <CardHeader>
                        <i className="fa fa-align-justify"/>
                        &nbsp;Gestion des tags
                    </CardHeader>

                    <WHTable
                        data={data}
                        pages={pages}
                        columns={this.columns()}
                        filterable={true}
                        onFetchData={this.load}
                        loading={!this.state.loaded}
                        defaultFiltered={this.state.defaultFiltered}
                        filterable
                    />
                </Card>
            </div>
        );
    }
}

CreateEditPopup.defaultProps = {};

export default TagsUniverses;