import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import FormEdit from "./Form";
import * as pageService from "../../../../services/cmsPage";
import { SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import Loading from "../../../../components/Loading";

class EditPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			data: {},
			loaded: true,
		};

		this.handleClose = this.handleClose.bind(this);
		this.handleOpen = this.handleOpen.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	handleOpen(data = null) {
		if (data) {
			this.setState({ loaded: true, data, open: true });
		} else {
			this.setState({ loaded: true, open: true });
		}
	}

	handleClose() {
		this.setState({ open: false, data: {} });
	}

	onSubmit(data) {
		const edit = !data.id ? pageService.create : pageService.update;

		return edit(data).then(data => {
			this.props.newData(data);

			toast.success("Enregistrement réussi");

			this.handleClose();
		});
	}

	render() {
		const { className } = this.props;
		const { loaded } = this.state;

		return (
			<Modal isOpen={this.state.open} className={className} fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					Editer
				</ModalHeader>

				{!loaded && <Loading />}
				{loaded && (
					<FormEdit
						onSubmit={this.onSubmit}
						initialValues={this.state.data}
					/>
				)}
			</Modal>
		);
	}
}

export default EditPopup;
