export default {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/blocs/header-scroll.html.twig",
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Header Scroll",
        fields: {
            title: {
                admin: {
                    template: "BlocTitle",
                    content:
                        '<h1 class="text-center size-huge weight-bold title mt-90 lh-9">Ne cherchez-plus, les projets technologiques d\'envergures sont ici !</h1>',
                },
            },
            subtitle: {
                admin: {
                    template: "BlocText1",
                    content:
                        '<p class="text-center size-big weight-light">Scrollez et découvrez</p><p class="text-center size-big weight-bold mt-15">Les différentes étapes de fonctionnement Castor</p>',
                },
            },
        },
    },
};