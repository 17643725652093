import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import Moment from "react-moment";
import { Card, CardHeader, Button } from "reactstrap";
import CreatePopup from "./Popup/PopupCreate";
import EditPopup from "./Popup/PopupEdit";
import {
	getLocal,
	getLocals,
	setLocal,
	getDefaultLocal,
} from "../../../reducers/params";
import WHTable from "../../../components/WHTable";
import * as pageService from "../../../services/cmsPage";

class Pages extends Component {
	state = {
		data: [],
		pages: null,
		loaded: true,
	};

	columns = () => {
		const { local, locals } = this.props;
		return [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				minWidth: 200,
				Cell: row => (
					<div>
						<Button
							color="primary"
							onClick={() => this.showdetails(row.value)}
						>
							<i className="fa fa-edit" /> <img style={{width: '20px'}} src="img/fr.png" alt=""/>
						</Button>
						<Button
							color="info"
							onClick={() => this.showdetails(row.value, 'en')}
						>
							<i className="fa fa-edit" /> <img style={{width: '20px'}} src="img/en.png" alt=""/>
						</Button>
						<Button
							color="danger"
							onClick={() => this.remove(row.value)}
						>
							<i className="fa fa-trash" />
						</Button>
					</div>
				),
			},
			{
				Header: "#",
				accessor: "id",
			},
			{
				Header: "Type",
				accessor: "template",
			},
			{
				Header: "titre",
				accessor: "title",
				Cell: row => (
					<div dangerouslySetInnerHTML={{ __html: row.value }} />
				),
			},
			{
				Header: "Parent",
				accessor: "parent.title",
			},
			{
				Header: "Resumé",
				accessor: "summary",
			},
			{
				Header: "date de publication",
				accessor: "publication_date",
				Cell: row => <Moment format="DD/MM/YYYY">{row.value}</Moment>,
				maxWidth: 150,
				filterable: false,
			},
			{
				Header: "Langues",
				filterable: false,
				Cell: row => {
					return (
						<div style={{ textAlign: "right" }}>
							<Button
								color={
										row.original.status ===
										"published"
										? "success"
										: "danger"
								}
							>
								{'FR'}
							</Button>

							<Button
								color={
									row.original.statusEn ===
									"published"
										? "success"
										: "danger"
								}
							>
								{'EN'}
							</Button>
						</div>
					);
				},
			},
		];
	};

	showdetails = (id, locale) => {
		locale ? this.props.history.push(`/pages/edit/${id}?locale=${locale}`) : this.props.history.push(`/pages/edit/${id}`);
	};

	load = (state, instance) => {
		this.setState({ loaded: false });

		return pageService.search(state).then(data => {
			this.setState({ data: [] }, () => {
				this.setState({
					data: data["hydra:member"],
					pages: Math.ceil(data["hydra:totalItems"] / state.pageSize),
					loaded: true,
				});
			});
		});
	};

	remove = async toDelete => {
		if (
			window.confirm("Etes vous sûre de vouloir supprimer cette page ?")
		) {
			this.setState({ loaded: false });

			try {
				await pageService.remove(toDelete);
				const lst = [...this.state.data];
				const index = lst.findIndex(el => el.id == toDelete);
				if (index > -1) {
					lst.splice(index, 1);
					this.setState({ data: lst, loaded: true });
				}
				toast.success("Page supprimée");
			} catch (error) {
				this.setState({ loaded: true });
				alert(
					"Il existe des dépendances sur cette page, elle ne peut donc être supprimée",
				);
			}
		}
	};

	newData = data => {
		const lst = [...this.state.data];

		const index = lst.findIndex(el => el.id == data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({ data: lst });
	};

	render() {
		const { data, pages } = this.state;
		const { locals, defaultLocal } = this.props;

		return (
			<div className="animated fadeIn">
				<h1>Pages</h1>
				<CreatePopup
					ref="createPopup"
					onCreate={this.showdetails}
					defaultLocal={defaultLocal}
					locals={locals}
				/>
				<EditPopup ref="editPopup" newData={this.newData} />

				<Card>
					<CardHeader>
						<i className="fa fa-align-justify" /> Gestion des pages
						<div className="card-actions">
							<button
								onClick={() =>
									this.refs.createPopup.handleOpen()
								}
							>
								<i className="fa fa-plus" /> Créer
							</button>
						</div>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	}
}

Pages.defaultProps = {
	local: null,
	defaultLocal: null,
};

Pages.propTypes = {
	local: PropTypes.string,
	defaultLocal: PropTypes.string,
	locals: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
	local: getLocal(state),
	defaultLocal: getDefaultLocal(state),
	locals: getLocals(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setLocal,
		},
		dispatch,
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Pages);
