import React, { Component } from "react";
import WHTable from "../../components/WHTable";
import { Card, CardHeader, Button } from "reactstrap";
import CreateEditPopup from "./CreateEditPopup";
import * as tagsServices from "../../services/tag";

class Tags extends Component {
	
    constructor(props) {
        super(props);

		this.state = {
			data: [],
			col: [],
			pages: null,
			loaded: false,
			defaultFiltered: [],
		};

		let defaultFiltered = this.state.defaultFiltered;

		if (this.props.match.params.iswaiting === 'waiting') {
			defaultFiltered.push({ id: 'status', value: "draft" });
		} else {
			defaultFiltered.push({ id: 'status', value: "published" });
		}

	}

	columns() {
		return [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						<Button
							color="primary"
							onClick={() =>
								this.refs.createEditPopup.handleOpen(row.value)
							}
						>
							<i className="fa fa-edit" /> <img style={{width: '20px'}} src="/img/fr.png" alt=""/>
						</Button>

						<Button
							color="info"
							onClick={() =>
								this.refs.createEditPopup.handleOpen(row.value, 'en')
							}
						>
							<i className="fa fa-edit" /> <img style={{width: '20px'}} src="/img/en.png" alt=""/>
						</Button>

						<Button
							color="danger"
							onClick={() => this.remove(row.value)}
						>
							<i className="fa fa-trash" />
						</Button>
					</div>
				),
				maxWidth: 200,
			},
			{
				Header: "Nom",
				accessor: "name",
			},
		];
	}

	componentDidUpdate = (prevProps) => {

		if(this.props.match.params.iswaiting !== prevProps.match.params.iswaiting ) {
			let defaultFiltered = [];

			if (this.props.match.params.iswaiting === 'waiting') {
				defaultFiltered.push({ id: 'status', value: "draft" });
			} else {
				defaultFiltered.push({ id: 'status', value: "published" });
			}

			this.setState({
				defaultFiltered: defaultFiltered,
			});
	   };

	};

	load = (state = null) => {
		if (state === null) state = this.state;
		this.setState({
			loaded: false,
		});

		return tagsServices.search(state).then(data => {
			this.setState(
				{
					data: [],
				},
				() => {
					this.setState({
						data: data["hydra:member"],
						pages: Math.ceil(
							data["hydra:totalItems"] / state.pageSize,
						),
						loaded: true,
					});
				},
			);
		});
	};

	remove = toDelete => {
		if (window.confirm("Êtes-vous sûr de vouloir supprimer ce tag ?")) {
			this.setState({
				loaded: false,
			});

			tagsServices.remove(toDelete)
				.then(() => {
					let lst = [...this.state.data];

					let index = lst.findIndex(el => el.id === toDelete);

					if (index > -1) {
						lst.splice(index, 1);

						this.setState({
							data: lst,
							loaded: true,
						});
					}
				})
				.catch(() => {
					alert(
						"Il existe des dépendances avec ce tag, il ne peut donc être supprimé",
					);
				});
		}
	};

	newData = data => {
		
		let lst = [...this.state.data];

		let isWaiting  = this.props.match.params.iswaiting === 'waiting' ? true : false;

		let index = lst.findIndex(el => el.id === data.id);

		if (index > -1 && data.status !== "draft" ) {
			lst = lst.filter(item => item.id !== data.id);
		}
		else if (index > -1) {
			lst[index] = data;
		} else if (isWaiting && data.status === "draft")  {
			lst.push(data);
		}

		this.setState({
			data: lst,
		});
	};

	render() {
		const { data, pages } = this.state;
		return (
			<div className="animated fadeIn">
				<CreateEditPopup
					ref={"createEditPopup"}
					newData={this.newData}
					load={this.load}
				/>
				<h1>Tags</h1>
				<Card>
					<CardHeader>
						<i className="fa fa-align-justify" />
						&nbsp;Gestion des tags
						<div className="card-actions">
							<button
								onClick={() =>
									this.refs.createEditPopup.handleOpen()
								}
								className={"btn btn-success"}
							>
								<i className={"fa fa-plus"} />
								&nbsp;Nouveau
							</button>
						</div>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
						defaultFiltered={this.state.defaultFiltered}
						filterable
						/>
				</Card>
			</div>
		);
	}
}
CreateEditPopup.defaultProps = {};

export default Tags;