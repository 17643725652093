import { fetch } from "../../utils/fetch";
import QueryBuilder from "../../components/WHTable/queryBuilder";
import configApi from "../../constants/ApiConfig";

const endPoints = "/api/menu_items";

export const search = data => {
	let query = QueryBuilder(data);

	return fetch(`${configApi.url}${endPoints}`, "get", query);
};

export const view = id => fetch(`${configApi.url}${endPoints}/${id}`, "get");
export const viewEn = (id) => fetch(`${configApi.url}${endPoints}/${id}`, "get", {}, {},  {'X-LOCALE':'en'});

export const create = data => fetch(`${configApi.url}${endPoints}`, "post", data);

export const update = data => fetch(`${configApi.url}${endPoints}/${data.id}`, "put", data);
export const updateEn = data => fetch(`${configApi.url}${endPoints}/${data.id}`, "put", data, {}, {'X-LOCALE':'en'});

export const parent = id => fetch(`${configApi.url}${endPoints}/${id}/parent`, "get");

export const remove = id => fetch(`${configApi.url}${endPoints}/${id}`, "delete", {});
