import React from "react";
import PropTypes from "prop-types";
import MediaContent from "./mediaContent";
import PopupEditContent from "../popupMedia/PopupMedia";
import { change } from "redux-form";

class Media extends React.Component {
	_popupEditContent = React.createRef();

	onChange = data => {
		this.props.meta.dispatch(
			change(this.props.meta.form, this.props.input.name, data),
		);
	};

	render = () => {
		const { data, input } = this.props;

		return (
			<div
				className="tpl-media"
				onClick={() =>
					this._popupEditContent.current.handleOpen(data, "media")
				}
			>
				<PopupEditContent
					ref={this._popupEditContent}
					onChange={this.onChange}
				/>
				{input && input.value && (
					<MediaContent admin={data} data={input.value} />
				)}
				{!input || (!input.value && <i className="fa fa-image" />)}
			</div>
		);
	};
}

Media.defaultProps = {
	onEdit: () => {},
};
Media.propTypes = {
	onEdit: PropTypes.func.isRequired,
};

export default Media;
