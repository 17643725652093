import React, { Component } from "react";
import Loading from "../../../components/Loading";
import JobForm from "./JobForm";

import { SubmissionError } from "redux-form";

import { toast } from "react-toastify";
import * as jobService from "../../../services/universe/job";

class JobView extends Component {
	state = {
		data: {status: 'draft'},
		loaded: false,
	};

	componentDidMount = () => {
		this.load();
	};

	load = async () => {

		const { id } = this.props.match.params;

		const urlParams = new URLSearchParams(window.location.search);
		const locale = urlParams.get('locale') ? urlParams.get('locale') : 'fr';

		if (id) {
			let data = await jobService.view(id, locale);
			data.sourcesArray = data.sources && data.sources.split(',').map( val => ({link: val}));

			data.subUniverses = data.subUniverses.map( val => ({ id: val.id, value: val.id, label: val.title }))
			data.universes = data.universes.map( val => ({ id: val.id, value: val.id, label: val.title }))
			data.activitySectors = data.activitySectors.map( val => ({ id: val.id, value: val.id, label: val.name }))


			this.setState({
				data,
				loaded: true,
				locale
			});
		} else {
			this.setState({
				loaded: true,
				locale
			});
		}
	};

	submit = values => {
		const edit = !values.id ? jobService.create : jobService.update;
		const caseForm = !values.id ? "Création réussie" : "Modification réussie";

		const urlParams = new URLSearchParams(window.location.search);
		const locale = urlParams.get('locale');

		if(values.sourcesArray) {
			values.sources = values.sourcesArray.map( val => val.link).join(',');
		}

		return edit(values, locale)
			.then(() => {
				this.props.history.push("/jobs");
				toast.success(caseForm);
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	render() {
		const { data, locale } = this.state;

		return (
			<div className="animated fadeIn">
				{!this.state.loaded && <Loading />}
				{this.state.loaded && (
					<div>
						<JobForm
							ref="form"
							onSubmit={this.submit}
							initialValues={data}
							locale={locale}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default JobView;
