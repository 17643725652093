import React, {Component} from 'react';
import {Modal, ModalHeader} from 'reactstrap';
import * as partnerService from '../../services/partner';
import * as advantageService from '../../services/advantage';
import {SubmissionError} from 'redux-form';
import {toast} from 'react-toastify';
import Loading from '../../components/Loading';
import PartnerForm from "./PartnerForm";

class CreateEditPopup extends Component {

    state = {
        open: false,
        data: {},
        loaded: true,
        advantages: [],
        locale: 'fr'
    };

    handleOpen = (id = null, locale= 'fr') => {
        this.setState({
            open: true,
            locale
        });

        advantageService.search().then(data => {
            this.setState({
                advantages: data["hydra:member"].map(el => ({
                    label: el.name,
                    value: el["@id"],
                })),
            });
        });

        if (id) {
            this.setState({
                loaded: false
            });

            partnerService.view(id, locale).then((data) => {
                this.setState({
                    data,
                    loaded: true
                });
            });
        }
    };

    handleClose = () => {
        this.setState({
            open: false,
            data: {}
        });
    };

    onSubmit = (data) => {
        const edit = (!data.id) ? partnerService.create : partnerService.update;

        return edit(data, this.state.locale).then((data) => {
            this.props.newData(data);

            toast.success('Enregistrement réussi');

            this.handleClose();
        }).catch((error) => {
            throw new SubmissionError(error);
        });
    };

    render() {
        const {className} = this.props;
        const {loaded, open, advantages, locale} = this.state;

        return (

            <Modal isOpen={open} className={className} fade={false}>

                <ModalHeader toggle={this.handleClose}>Editer</ModalHeader>

                {!loaded &&
                <Loading/>
                }

                {loaded &&
                <div>
                    <PartnerForm advantages={advantages} onSubmit={this.onSubmit} initialValues={this.state.data} locale={locale} />
                </div>
                }

            </Modal>
        );
    }
}

export default CreateEditPopup