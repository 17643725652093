import React from 'react';
import { Button } from 'reactstrap';
import openMediaBox from './utils/openMediaBox';

const MediaLibrary = () => (
	<Button color="primary" onClick={openMediaBox}>
		Médias
	</Button>
);

export default MediaLibrary;
