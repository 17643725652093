import React from "react";
import classnames from "classnames";
import Paragraph from "./paragraph";

class BlocText1 extends React.Component {
	render = () => {
		const { onSelect, data } = this.props;
		return (
			<div className={classnames("tpl-bloc")} onClick={onSelect}>
				<Paragraph data={data} {...this.props} />
			</div>
		);
	};
}

export default BlocText1;
