/* eslint-disable import/no-unresolved */
import {
	PARAMS_RK,
	PARAMS_LOCALS_RK,
	PARAMS_LOCAL_RK,
	PARAMS_DEFAULT_LOCAL_RK,
	PARAMS_CMS_STATUS,
	PARAMS_SEO_ROBOTS,
	PARAMS_TEMPLATES,
	PARAMS_CMSTPLTS,
	PARAMS_ROLES,
	PARAMS_CONTACT_TYPES,
} from "./constants";

export const getParams = state => state[PARAMS_RK];
export const getLocals = state => state[PARAMS_RK][PARAMS_LOCALS_RK];
export const getLocal = state => state[PARAMS_RK][PARAMS_LOCAL_RK];
export const getDefaultLocal = state =>
	state[PARAMS_RK][PARAMS_DEFAULT_LOCAL_RK];
export const getCmsStatus = state => state[PARAMS_RK][PARAMS_CMS_STATUS];
export const getSeoRobots = state => state[PARAMS_RK][PARAMS_SEO_ROBOTS];
export const getTemplates = state => state[PARAMS_RK][PARAMS_TEMPLATES];
export const getCmsTemplates = state => state[PARAMS_RK][PARAMS_CMSTPLTS];
export const getRoles = state => state[PARAMS_RK][PARAMS_ROLES];
export const getContactTypes = state => state[PARAMS_RK][PARAMS_CONTACT_TYPES];
