import React, { Component } from "react";
import Loading from "../../../components/Loading";
import SubUniversForm from "./SubUniversForm";

import { SubmissionError } from "redux-form";

import { toast } from "react-toastify";
import * as subUniversService from "../../../services/universe/subuniverse";

class SubUniversView extends Component {
	state = {
		data: {},
		loaded: false,
	};

	componentDidMount = () => {
		this.load();
	};

	load = async () => {

		const { id } = this.props.match.params;

		const urlParams = new URLSearchParams(window.location.search);
		const locale = urlParams.get('locale') ? urlParams.get('locale') : 'fr';

		if (id) {
			let data = await subUniversService.view(id, locale);

			if(data.universe) {
				Object.assign(data.universe, {value: data.universe.id, label: data.universe.title});
			}

			if(data.activitySector) {
				Object.assign(data.activitySector, {value: data.activitySector.id, label: data.activitySector.name});
			}

			data.jobs = data.jobs.map( val => ({ id: val.id, value: val.id, label: val.title }));

			if(data.sources) {
				data.sourcesArray = data.sources.split(',').map(val => ({link: val}));
			}

			this.setState({
				data,
				loaded: true,
				locale
			});
		} else {
			this.setState({
				loaded: true,
				locale
			});
		}
	};

	submit = values => {
		const edit = !values.id ? subUniversService.create : subUniversService.update;
		const caseForm = !values.id ? "Création réussie" : "Modification réussie";

		const urlParams = new URLSearchParams(window.location.search);
		const locale = urlParams.get('locale');

		if(values.sourcesArray) {
			values.sources = values.sourcesArray.map( val => val.link).join(',');
		}

		return edit(values, locale)
			.then(() => {
				this.props.history.push("/sous-univers");
				toast.success(caseForm);
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	render() {
		const { data, locale } = this.state;

		return (
			<div className="animated fadeIn">
				{!this.state.loaded && <Loading />}
				{this.state.loaded && (
					<div>
						<SubUniversForm
							ref="form"
							onSubmit={this.submit}
							initialValues={data}
							locale={locale}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default SubUniversView;
