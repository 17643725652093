/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";

class FieldSimpleInput extends Component {
	test = () => {};

	render = () => {
		const {
			input,
			type,
			placeholder,
			meta: { touched, error },
		} = this.props;

		return (
			<Input
				placeholder={placeholder}
				{...input}
				type={type}
				{...(touched && error ? { valid: false } : {})}
			/>
		);
	};
}

FieldSimpleInput.defaultProps = {
	mask: null,
	input: {},
	label: null,
	type: null,
	help: null,
	bsSize: null,
	placeholder: null,
	id: null,
	disabled: false,
	meta: {
		touched: false,
		error: null,
	},
};

FieldSimpleInput.propTypes = {
	mask: PropTypes.string,
	input: PropTypes.object,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	type: PropTypes.string,
	help: PropTypes.string,
	bsSize: PropTypes.string,
	placeholder: PropTypes.string,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	meta: PropTypes.object,
};

export default FieldSimpleInput;
