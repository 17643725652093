import ApiConfig from '../../../../../constants/ApiConfig';

import * as sessionService from '../../../../../utils/session/index';

const openMediaBox = () => {
	const token = sessionService.getJwtToken();

	const params = {
		access_token: `${token}`,
		type: 'id',
		url: ApiConfig.finderParams.url,
		endPointMedia: ApiConfig.finderParams.endPointMedia,
		assetUrl: ApiConfig.finderParams.assetUrl,
	};

	let str = '';
	for (const key in params) {
		if (str !== '') {
			str += '&';
		}
		str += `${key}=${encodeURIComponent(params[key])}`;
	}

	const url = `/wh-finder/index.html?${str}#/`;

	window.open(
		url,
		'wh media center',
		'location=no, menubar=no, status=no, scrollbars=no, menubar=no, width=900, height=600'
	);
};

export default openMediaBox;
