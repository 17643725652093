import React, { Component } from "react";
import { Form, Row, Col } from "reactstrap";
import { Field, reduxForm, getFormValues } from "redux-form";
import { FieldTxt, FieldSelect } from "../../../../components/Form/wh-field";
import { ModalBody, ModalFooter, Alert } from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";

import { connect } from "react-redux";
import { getLocal } from "../../../../reducers/params";
import FieldTranslate from "../../../../components/Form/whFields/FieldTranslate";

class ParametersFormPopUp extends Component {
	state = {
		open: false,
		parameterType: "string",
	};

	change = event => {
		this.setState({ parameterType: event.target.value });
	};

	render = () => {
		const {
			local,
			error,
			handleSubmit,
			submitting,
		} = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>
					<Row>
						<Col md={8}>
							<Field
								label="Type de page : "
								name="entity"
								required={false}
								component={FieldSelect}
								options={[]}
							/>
						</Col>
						<Col md={4}>
							<Field
								label="Id de la page :"
								name="entityId"
								component={FieldTxt}
								type="text"
							/>
						</Col>
					</Row>

					<FieldTranslate
						label="Title :"
						name="title"
						translatable={local}
						component={FieldTxt}
						type="text"
					/>

					{error && (
						<Alert color="danger">
							<div dangerouslySetInnerHTML={{ __html: error }} />
						</Alert>
					)}
				</ModalBody>

				<ModalFooter>
					<LaddaButton
						className="btn btn-primary btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type={"submit"}
					>
						<i className="fa fa-save" />
						&nbsp;Enregistrer
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	};
}

const formName = "SeoFormPopUp";
const ReduxFormWrapped = reduxForm({
	form: formName,
})(ParametersFormPopUp);

const mapState = state => ({
	currentValues: getFormValues(formName)(state),
	local: getLocal(state),
});

const FullWrappedParametersFormPopup = connect(mapState)(ReduxFormWrapped);

export default FullWrappedParametersFormPopup;
