/**
 * Manage breadcrump name
 */
const routes = {
    '/': 'Accueil',
    '/dashboard': 'Dashboard',
    '/pages': 'Gestion des pages',
    '/users': 'Gestion des utilisateurs',
    '/branches': 'Gestion des Secteurs',
    '/tags': 'Gestion des tags',
    '/waiting_tags': 'Gestion des tags en attente',
    '/advantages': 'Gestion des avantages',
    '/membres': 'Gestion des membres',
    '/parametres': 'Gestion des parametres',
    '/menus': 'Gestion des menus',
    '/temoignages': 'Gestion des témoignages',
    '/partenaires': 'Gestion des partenaires',
    '/univers': 'Gestion des Univers',
    '/sous-univers': 'Gestion des Sous Univers',
    '/jobs': 'Gestion des pages métiers',
    '/news': 'Gestion des News',
};
export default routes;
