import React from "react";
import PropTypes from "prop-types";
import CollectionContent from "./collectionContent";
class Collection extends React.Component {
	render = () => {
		const { onEdit, data } = this.props;

		if (data && data.collection) {
			return (
				<div className="tpl-media" onClick={() => onEdit("collection")}>
					<CollectionContent data={data} />
				</div>
			);
		} else {
			return (
				<div className="tpl-media" onClick={() => onEdit("collection")}>
					<i className="fa fa-th-large" />
				</div>
			);
		}
	};
}

Collection.defaultProps = {
	onEdit: () => {},
};
Collection.propTypes = {
	onEdit: PropTypes.func.isRequired,
};

export default Collection;
