import home from "./home";
import page from "./page";
import contact from "./contact";
import concept from "./concept";
import howItWorks from "./howItWorks";
import partners from "./partners";
import society from "./society";
import engagements from "./engagements";
import team from "./team";
import missions from "./missions";
import branches from "./branches";
import cgu from "./cgu";
import community from "./community";

export default {
	home,
	page,
	contact,
	concept,
	howItWorks,
	partners,
    society,
	engagements,
	team,
	missions,
	branches,
	cgu,
	community
};
