import React from "react";
import {
	ButtonDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
} from "reactstrap";

class Action extends React.Component {
	state = {
		dropdownOpen: false,
	};
	render = () => {
		const {
			remove,
			copy,
			orderDown,
			orderUp,
			popupEdit,
			editable,
		} = this.props;

		return (
			<div className="card-actions">
				<ButtonDropdown
					isOpen={this.state.dropdownOpen}
					toggle={() =>
						this.setState({
							dropdownOpen: !this.state.dropdownOpen,
						})
					}
				>
					<DropdownToggle caret>
						<i className="fa fa-cog" />
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem onClick={() => popupEdit()}>
							<i className="fa fa-edit" /> Editer
						</DropdownItem>
						<DropdownItem onClick={() => orderUp()}>
							<i className="fa fa-chevron-up" /> Monter
						</DropdownItem>
						<DropdownItem onClick={() => orderDown()}>
							<i className="fa fa-chevron-down" /> Descendre
						</DropdownItem>
						<DropdownItem onClick={() => copy()}>
							<i className="fa fa-copy" /> Copie
						</DropdownItem>
						<DropdownItem divider />
						<DropdownItem onClick={() => remove()}>
							<i className="fa fa-trash" /> Supprimer
						</DropdownItem>
					</DropdownMenu>
				</ButtonDropdown>
			</div>
		);
	};
}

export default Action;
