import React from "react";

import {
    Field, FieldArray, FormSection,
    reduxForm,

} from "redux-form";

import {
    Card,
    CardHeader,
    CardBody,
    Form, NavItem, NavLink, Nav, TabContent, TabPane, ModalBody,

} from "reactstrap";

import LaddaButton, {EXPAND_LEFT} from "react-ladda";
import classnames from "classnames";

import {
    WhFieldTxt,
    WhValidator,
    WhFieldSelectAsync,
    WhFieldCkEditor, WhFile, WhFieldSelect,
} from "../../../components/Form/whFields";
import * as tagsService from "../../../services/tag";
import * as subUniverseService from "../../../services/universe/subuniverse";
import * as universeService from "../../../services/universe/universe";
import {getCmsStatus, getSeoRobots} from "../../../reducers/params";
import {connect} from "react-redux";
import FormSeo from "../../Cms/Seo/Form";
import ApiConfig from "../../../constants/ApiConfig";
import SourcesField from "../SourcesField";
import * as branchService from "../../../services/branch";

class JobForm extends React.Component {
    state = {
        activeTab: "1",
    };

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    loadSubUniverses = async input => {
        const data = await subUniverseService.search({
            filtered: [{ id: "title", value: input },  { id: "pagination", value: '0' }],
        });

        const lst = data["hydra:member"].map(el => ({
            value: el.id,
            id: el.id,
            label: `${el.title}`,
        }));


        return { options: lst };
    };

    loadUniverses = async input => {
        const data = await universeService.search({
            filtered: [{ id: "title", value: input }, { id: "pagination", value: '0' }],
        });

        const lst = data["hydra:member"].map(el => ({
            value: el.id,
            id: el.id,
            label: `${el.title}`,
        }));

        return { options: lst };
    };

    loadSectors = async input => {
        const data = await branchService.search({
            filtered: [{ id: "name", value: input }],
        });

        const lst = data["hydra:member"].map(el => ({
            value: el.id,
            id: el.id,
            label: `${el.name}`,
        }));

        return { options: lst };
    };

    loadTags = input => {
        return tagsService
            .search({
                filtered: [
                    {id: "name", value: input},
                    {id: "exists[universe]", value: '0'},
                    {id: "exists[subUniverse]", value: '0'},
                    {id: "exists[job]", value: '0'},
                    {id: "pagination", value: '0'},
                    {id: "status", value: 'published'}
                ]
            })
            .then(data => {
                const lst = data["hydra:member"].map((el, index) => {
                    return {id: el.id, label: `${el.name}`};
                });
                return {options: lst};
            });
    };

    render = () => {
        const {
            handleSubmit,
            locale,
            initialValues,
            submitting,
            seoRobots,
            cmsStatus
        } = this.props;

        const style = locale ? locale == 'en' ? {
            backgroundSize: "20px",
            backgroundImage: `url("/img/en.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        } : {
            backgroundSize: "20px",
            backgroundImage: `url("/img/fr.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        } : {
            backgroundSize: "20px",
            backgroundImage: `url("/img/fr.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        };

        const creation = Object.keys(initialValues).length < 2 ;

        return (
            <Form onSubmit={handleSubmit}>
                <Card>
                    <CardHeader>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            "1",
                                    })}
                                    onClick={() => {
                                        this.toggle("1");
                                    }}
                                >
                                    Contenu
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            this.state.activeTab ===
                                            "2",
                                    })}
                                    onClick={() => {
                                        this.toggle("2");
                                    }}
                                >
                                    SEO
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <div className="card-actions">
                            {!creation && (
                                <a
                                    href={`${ApiConfig.url}/fr/metier/${
                                        initialValues.id
                                    }/${initialValues.slug}/`}
                                    target="_blank"
                                    className="btn btn-block btn-success btn-ld"
                                >
                                    Voir
                                </a>
                            )}
                            <LaddaButton
                                className="btn btn-success btn-ladda"
                                loading={submitting}
                                data-style={EXPAND_LEFT}
                                type="submit"
                            >
                                <i className="fa fa-save"/>
                                &nbsp;Enregistrer
                            </LaddaButton>
                        </div>
                    </CardHeader>

                    <CardBody>

                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Field
                                    label="Titre :"
                                    name="title"
                                    component={WhFieldTxt}
                                    type="text"
                                    style={style}
                                    validate={[WhValidator.required]}
                                />

                                <Field
                                    label="Sous Titre :"
                                    name="subTitle"
                                    component={WhFieldTxt}
                                    type="text"
                                    style={style}
                                    placeholder={'Les enjeux du métier ..'}
                                    validate={[WhValidator.required]}
                                />
                                <Field
                                    label="Statut de publication :"
                                    name="status"
                                    required
                                    validate={[WhValidator.required]}
                                    component={WhFieldSelect}
                                    type="select"
                                    options={cmsStatus}
                                    style={style}
                                />
                                <Field
                                    label="Description :"
                                    name="description"
                                    component={WhFieldCkEditor}
                                    type="textarea"
                                    style={style}
                                    validate={[WhValidator.required]}
                                />

                                <Field
                                    label="Secteurs liés : "
                                    name="activitySectors"
                                    component={WhFieldSelectAsync}
                                    type="select"
                                    loadOptions={this.loadSectors}
                                    multi
                                />

                                <Field
                                    label="Univers liés"
                                    name="universes"
                                    component={WhFieldSelectAsync}
                                    type="select"
                                    loadOptions={this.loadUniverses}
                                    multi
                                />

                                <Field
                                    label="Sous-Secteurs liés"
                                    name="subUniverses"
                                    component={WhFieldSelectAsync}
                                    type="select"
                                    loadOptions={this.loadSubUniverses}
                                    multi
                                />

                                <Field
                                    label="Bannière"
                                    name="banner"
                                    component={WhFile}
                                    apiUrl={true}
                                    type="text"
                                />

                                {creation && (
                                    <Field
                                        label="Tag lié :"
                                        name="tag"
                                        component={WhFieldSelectAsync}
                                        type="select"
                                        loadOptions={this.loadTags}
                                        validate={[WhValidator.required]}
                                    />
                                )}

                                <h6>Sources</h6>
                                <FieldArray
                                    name={`sourcesArray`}
                                    component={SourcesField}
                                />

                            </TabPane>
                            <TabPane tabId="2">
                                <FormSection name="seo">
                                    <FormSeo
                                        seoRobots={seoRobots}
                                    />
                                </FormSection>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Form>
        );
    };
}

const mapStateToProps = (state) => ({
    seoRobots: getSeoRobots(state),
    cmsStatus: getCmsStatus(state),
});

JobForm = connect(
    mapStateToProps
)(JobForm);

export default reduxForm({
    form: 'JobForm'
})(JobForm);
