export const required = value => {
	if (!value) {
		return "Obligatoire";
	}

	return undefined;
};
export const email = value =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? "Adresse email non valide"
		: undefined;
export const numeric = value =>
	value && !/^[0-9]*$/i.test(value)
		? "Ce champ ne peut contenir que des chiffres"
		: undefined;

export const phone = value =>
	value &&
	!/^([0-9]{2}) ([0-9]{2}) ([0-9]{2}) ([0-9]{2}) ([0-9]{2})$/i.test(value)
		? "Ce n’est pas pas un numéro valide"
		: false;