import { fetch } from "../../utils/fetch";
import QueryBuilder from "../../components/WHTable/queryBuilder";
import configApi from "../../constants/ApiConfig";

const endPoints = "/api/app_parameters";
const endPointEntity = "/api/parameters";

export const search = data => {
	let query = QueryBuilder(data);

	return fetch(`${configApi.url}${endPointEntity}`, "get", query);
};

export const view = (id, lang = null) => fetch(`${configApi.url}${endPointEntity}/${id}`, "get", {}, {}, lang ? {'X-LOCALE':lang} : null);

export const create = data =>
	fetch(`${configApi.url}${endPointEntity}`, "post", data);

export const update = (data, lang = null) => fetch(`${configApi.url}${endPointEntity}/${data.id}`, "put", data, {}, lang ? {'X-LOCALE':lang} : null);

export const remove = id =>
	fetch(`${configApi.url}${endPointEntity}/${id}`, "delete");

export const getParams = () => fetch(`${configApi.url}${endPoints}`, "get");
