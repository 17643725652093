import {
	SET_PARAMS,
	SET_LOCAL,
	PARAMS_LOCALS_RK,
	PARAMS_LOCAL_RK,
	PARAMS_DEFAULT_LOCAL_RK,
	PARAMS_SEO_ROBOTS,
	SET_DEFAULT_LOCAL,
	SET_PARAMS_CMS_STATUS,
	SET_PARAMS_SEO_ROBOTS,
	SET_TEMPLATES,
	PARAMS_CMS_STATUS,
	PARAMS_TEMPLATES,
	PARAMS_CMSTPLTS,
	PARAMS_CONTACT_TYPES,
} from "./constants";

const defaultValues = {
	[PARAMS_LOCALS_RK]: [],
	[PARAMS_LOCAL_RK]: "fr",
	[PARAMS_DEFAULT_LOCAL_RK]: "fr",
	[PARAMS_CMS_STATUS]: [],
	[PARAMS_SEO_ROBOTS]: [],
	[PARAMS_TEMPLATES]: [],
	[PARAMS_CMSTPLTS]: [],
	[PARAMS_CONTACT_TYPES]: [],
};

const paramsReducer = (state = defaultValues, { payload, type }) => {
	const newState = { ...state };
	switch (type) {
		case SET_PARAMS:
			return { ...payload };
		case SET_LOCAL:
			newState[PARAMS_LOCAL_RK] = payload;
			return newState;
		case SET_DEFAULT_LOCAL:
			state[PARAMS_DEFAULT_LOCAL_RK] = payload;
			return state;
		case SET_PARAMS_CMS_STATUS:
			state[PARAMS_CMS_STATUS] = payload;
			return state;
		case SET_PARAMS_SEO_ROBOTS:
			state[PARAMS_SEO_ROBOTS] = payload;
			return state;
		case SET_TEMPLATES:
			state[PARAMS_TEMPLATES] = payload;
			return state;
		default:
			return newState;
	}
};

export default paramsReducer;
