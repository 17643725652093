import {threeColumns} from "./engagements";

const community01Container = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Titre ",
        fields: {
            background: {
                admin: {
                    template: "BlocMedia",
                    type: "tplt-picture",
                },
            },
            title: {
                admin: {
                    template: "BlocTitle",
                    content:
                        '<h1 class="text-center size-huge weight-bold lh-10"> Notre communauté </h1>',
                },
            },
        },
    },
};

export const column = {
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Colonne",
        fields: {
            title: {
                admin: {
                    template: "BlocText1",
                    content: '<span class="weight-light size-big">Ils nous font confiance</span>',
                },
            },
            subtitle: {
                admin: {
                    template: "BlocText1",
                    content: '<span class="weight-bold size-big mt-10">Découvrez leurs témoignages </span>',
                },
            },
        },
    },
};

export const twoColumns = {
    admin: {
        template: "BlocCollection",
        title: "Bloc trois colonnes",
        type: "tplt-slider-horizontal",
        add: true,
        editable: true,
        defaultTemplates: [column],
    },
};

const community01 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/community/community01.html.twig",
    admin: {
        template: "BlocCollection",
        title: "Bloc 1",
        editable: true,
    },
    contents: [community01Container, twoColumns],
};

const community02 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/community/community02.html.twig",
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Freelance ou entreprise",
        fields: {
            title: {
                admin: {
                    template: "BlocText1",
                    content:
                        '<div class="flex-selfStart text-left sectionContent sectionContent--gridRow"> <h2 class="size-title weight-light">Freelance ou entreprise ?</h2> <h2 class="size-title weight-bold">Découvrez leurs témoignages !</h2> </div>',
                },
            },
        },
    },
};

const community03 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/community/community03.html.twig",
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Philosophie",
        fields: {
            background: {
                admin: {
                    template: "BlocMedia",
                    type: "tplt-picture",
                },
            },
            paragraphe: {
                admin: {
                    template: "BlocText1",
                    content:
                        '<h2 class=" size-title weight-bold">Notre philosophie</h2> <p class=" size-big weight-bold mt-30 lh-16"> On vous accompagne de votre inscription à votre paiement. C’est vous qui décidez. adhibens modum orientis latera cuncta vexabat nec honoratis parcens nec urbium primatibus nec plebeiis. </p><p class=" size-big weight-light mt-30 lh-16"> Denique Antiochensis ordinis vertices sub uno elogio iussit occidi ideo efferatus, quod ei celebrari vilitatem intempestivam urgenti, cum inpenderet inopia, gravius rationabili responderunt; et perissent ad unum ni comes orientis tunc Honoratus fixa constantia restitisset. </p>',
                },
            },
        },
    },
};

/**
 * EXPORT
 */
export default {
    template: "community",
    name: "Notre Communauté",
    editable: true,
    add: false,
    seo: {
        robots: "index,follow",
    },
    builder: [community01, community02, community03],
};
