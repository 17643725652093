import React from "react";

class CollectionContent extends React.Component {
	render = () => {
		const { data } = this.props;

		return "collection";
	};
}

export default CollectionContent;
