import React from "react";
import classnames from "classnames";
import PopupRequest from "../popupRequest/PopupRequest";
import { Button } from "reactstrap";
import { change } from "redux-form";
import { fetch } from "../../../../../utils/fetch";
import configApi from "../../../../../constants/ApiConfig";
import WHTable from "../../../../../components/WHTable";

class BlocRequest extends React.Component {
	_popupRequest = React.createRef();
	state = {
		res: [],
	};

	componentDidMount = () => {
		this.load();
	};

	componentDidUpdate = oldProps => {
		const { input } = this.props;

		if (input != oldProps.input) {
			this.load();
		}
	};

	load = async () => {
		const { input, data } = this.props;

		const service =
			!input || !input.value ? data.admin.content : input.value;

		const res = await fetch(
			`${configApi.url}${service.service}`,
			"get",
			service.filters,
		);

		this.setState(
			{ data: [] },
			this.setState({
				res: res["hydra:member"],
			}),
		);
	};

	onChange = data => {
		this.props.meta.dispatch(
			change(this.props.meta.form, this.props.input.name, data),
		);
	};
	render = () => {
		const { data, input } = this.props;
		const { res } = this.state;
		const value = !input || !input.value ? data.admin.content : input.value;

		return (
			<div className={classnames("tpl-bloc")}>
				<PopupRequest
					ref={this._popupRequest}
					onChange={this.onChange}
				/>

				<WHTable
					data={res}
					columns={[
						{
							Header: "#",
							accessor: "id",
							width: 80,
						},
						{
							Header: "Titre",
							accessor: "name",
						},
					]}
					pageSize={res.length}
					showPagination={false}
					defaultPageSize={res.length}
					filterable={false}
				/>

				<Button
					type="button"
					color="primary"
					className="btn-block btn-lg"
					onClick={() => this._popupRequest.current.handleOpen(value)}
				>
					Editer la requête
				</Button>
			</div>
		);
	};
}

export default BlocRequest;
