import React from "react";
import classnames from "classnames";
import Media from "./media";

class BlocMedia extends React.Component {
	render = () => {
		const { data } = this.props;

		return (
			<div className={classnames("tpl-bloc")}>
				<Media data={data} {...this.props} />
			</div>
		);
	};
}

export default BlocMedia;
