const engagements01Container = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Titre ",
        fields: {
            background: {
                admin: {
                    template: "BlocMedia",
                    type: "tplt-picture",
                },
            },
            title: {
                admin: {
                    template: "BlocTitle",
                    content:
                        '<h1 class="text-center size-huge weight-bold lh-10"> Nos engagements<br>sur-mesure<br>pour vos projets</h1>',
                },
            },
        },
    },
};

export const column = {
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Colonne",
        fields: {
            title: {
                admin: {
                    template: "BlocText1",
                    content: '<span class="weight-light size-big">Du sur-mesure</span>',
                },
            },
            subtitle: {
                admin: {
                    template: "BlocText1",
                    content: '<span class="weight-bold size-big mt-10">Nos quatre grands engagements </span>',
                },
            },
        },
    },
};

export const threeColumns = {
    admin: {
        template: "BlocCollection",
        title: "Bloc trois colonnes",
        type: "tplt-slider-horizontal",
        add: true,
        editable: true,
        defaultTemplates: [column],
    },
};

const engagements01 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/engagements/engagements01.html.twig",
    admin: {
        template: "BlocCollection",
        title: "Bloc 1",
        editable: true,
    },
    contents: [engagements01Container, threeColumns],
};

const engagements02Title = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Titre",
        fields: {
            background: {
                admin: {
                    template: "BlocMedia",
                    type: "tplt-picture",
                },
            },
            title: {
                admin: {
                    template: "BlocTitle",
                    content:
                        '<h2 class="size-title weight-light">Du sur-mesure !</h2>',
                },
            },
            subtitle: {
                admin: {
                    template: "BlocTitle",
                    content:
                        '<h2 class="size-title weight-bold">Nos 4 grands engagements</h2>',
                },
            },
        },
    },
};

export const engagement = {
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Colonne",
        fields: {
            icon: {
                admin: {
                    template: "BlocText1",
                    content: '<span class="icon-transparence size-blockTitle mb-15"></span>',
                },
            },
            title: {
                admin: {
                    template: "BlocTitle",
                    content: 'Conseil',
                },
            },
            subtitle: {
                admin: {
                    template: "BlocTitle",
                    content: '<p class="size-title weight-light mt-15">0 commission</p>',
                },
            },
            description: {
                admin: {
                    template: "BlocText1",
                    content: ' <p class="weight-light size-medium mt-15 lh-15">Decription + détaillé de l’engagement à fournir … ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
                },
            },
        },
    },
};

export const engagements = {
    admin: {
        template: "BlocCollection",
        title: "Nos engagements",
        type: "tplt-slider-horizontal",
        add: true,
        editable: true,
        defaultTemplates: [engagement],
    },
};

const engagements02 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/engagements/engagements02.html.twig",
    admin: {
        template: "BlocCollection",
        title: "Bloc 1",
        editable: true,
    },
    contents: [engagements02Title, engagements],
};

const engagement03 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/engagements/engagements03.html.twig",
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Freelance ou entreprise",
        fields: {
            title: {
                admin: {
                    template: "BlocText1",
                    content:
                        '<div class="flex-selfStart text-left sectionContent sectionContent--gridRow"> <h2 class="size-title weight-light">Freelance ou entreprise ?</h2> <h2 class="size-title weight-bold">Découvrez leurs témoignages !</h2> </div>',
                },
            },
        },
    },
};

const engagement04 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/engagements/engagements04.html.twig",
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Et vous ?",
        fields: {
            background: {
                admin: {
                    template: "BlocMedia",
                    type: "tplt-picture",
                },
            },
            title: {
                admin: {
                    template: "BlocText1",
                    content:
                        ' <p class="size-big weight-bold text-left flex-selfStart">Et vous ?</p><p class="size-medium weight-light mt-10">Vous souhaitez nous faire part de votre experience, n’hésitez pas à vous exprimer ici : </p> ',
                },
            },
        },
    },
};

/**
 * EXPORT
 */
export default {
    template: "engagements",
    name: "Nos engagements",
    editable: true,
    add: false,
    seo: {
        robots: "index,follow",
    },
    builder: [engagements01, engagements02, engagement03, engagement04],
};
