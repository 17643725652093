import React from "react";
import classnames from "classnames";
import Collection from "./collection";

class blocCollection extends React.Component {
	render = () => {
		const { onSelect, active, onEdit, data } = this.props;
		return (
			<div
				className={classnames("tpl-bloc", {
					active: active,
				})}
				onClick={onSelect}
			>
				<Collection data={data} onEdit={onEdit} />
			</div>
		);
	};
}

export default blocCollection;
