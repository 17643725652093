import React from "react";
import { Field, reduxForm } from "redux-form";
import { WhFieldTxt } from "../../components/Form/whFields";
import {
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Form,
	Alert,
} from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";

class FormPassword extends React.Component {
	test = () => {};

	render() {
		const { error, handleSubmit, submitting } = this.props;

		return (
			<Card>
				<Form onSubmit={handleSubmit} className="form-horizontal">
					<CardHeader>Mon mot de passe</CardHeader>

					<CardBody>
						<Field
							label="Saisir votre nouveau mot de passe :"
							name="password"
							component={WhFieldTxt}
							type="password"
						/>

						{error && <Alert color="danger">{error}</Alert>}

						<button
							type="submit"
							style={{ position: "absolute", left: "-100000px" }}
						/>
					</CardBody>

					<CardFooter>
						<LaddaButton
							className="btn btn-success btn-ladda"
							loading={submitting}
							data-style={EXPAND_LEFT}
							type="submit"
						>
							<i className="fa fa-save" />
							&nbsp;Enregistrer
						</LaddaButton>
					</CardFooter>
				</Form>
			</Card>
		);
	}
}

FormPassword = reduxForm({
	form: "FormPassword",
})(FormPassword);

export default FormPassword;
