import React from "react";

import {
    Field,
    reduxForm,

} from "redux-form";

import {
    Card,
    CardHeader,
    CardBody,
    Form,

} from "reactstrap";

import LaddaButton, { EXPAND_LEFT } from "react-ladda";

import {
    WhFieldTxt,
    WhValidator,
    WhFieldSelectAsync,
    WhFieldCkEditor, WhFile
} from "../../../components/Form/whFields";
import * as branchService from '../../../services/branch';

class PostForm extends React.Component {
    state = {
        activeTab: "1",
    };

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    loadSectors = async input => {
        const data = await branchService.search({
            filtered: [{ id: "name", value: input }],
        });

        const lst = data["hydra:member"].map(el => ({
            value: el.id,
            id: el.id,
            label: `${el.name}`,
        }));

        return { options: lst };
    };

    render = () => {
        const {
            handleSubmit,
            locale,
            initialValues,
            submitting
        } = this.props;

        const style = locale ? locale == 'en' ? {
            backgroundSize: "20px",
            backgroundImage: `url("/img/en.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        } : {
            backgroundSize: "20px",
            backgroundImage: `url("/img/fr.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        } : {
            backgroundSize: "20px",
            backgroundImage: `url("/img/fr.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        };

        const creation = Object.keys(initialValues).length === 0;

        return (
            <Form onSubmit={handleSubmit}>
                <Card>
                    <CardHeader>
                        {
                            creation ?
                                <span>Création d'une news</span>
                                 :
                                <span>
                                    Edition d'une news
                                </span>
                        }
                        <div className="card-actions">
                            <LaddaButton
                                className="btn btn-success btn-ladda"
                                loading={submitting}
                                data-style={EXPAND_LEFT}
                                type="submit"
                            >
                                <i className="fa fa-save" />
                                &nbsp;Enregistrer
                            </LaddaButton>
                        </div>
                    </CardHeader>

                    <CardBody>
                        <Field
                            label="Titre :"
                            name="title"
                            component={WhFieldTxt}
                            type="text"
                            style={style}
                            validate={[WhValidator.required]}
                        />

                        <Field
                            label="Lien :"
                            name="link"
                            component={WhFieldTxt}
                            type="text"
                            style={style}
                        />

                        <Field
                            label="Secteurs : (laisser vide pour afficher partout)"
                            name="activitySectors"
                            component={WhFieldSelectAsync}
                            type="select"
                            loadOptions={this.loadSectors}
                            multi
                        />

                        <Field
                            label="Résumé :"
                            name="resume"
                            component={WhFieldCkEditor}
                            type="textarea"
                            style={style}
                        />

                        <Field
                            label="Image"
                            name="image"
                            component={WhFile}
                            apiUrl={true}
                            type="text"
                        />

                    </CardBody>
                </Card>
            </Form>
        );
    };
}

export default reduxForm({
    form: 'PostForm'
})(PostForm);
