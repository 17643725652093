import React from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Col, FormGroup, FormText, Label, FormFeedback } from "reactstrap";
import PropTypes from "prop-types";
import "react-select/dist/react-select.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

export const FieldSelectAsync = ({
	name,
	input,
	label,
	multi,
	help,
	options,
	loadOptions,
	meta: { touched, error },
}) => {
	if (touched && error) console.log(error, input.name);
	return (
		<FormGroup row>
			<Col md="3">{label && <Label>{label}</Label>}</Col>

			<Col xs="12" md="9">
				<Select.Async
					name={name}
					placeholder="Chercher"
					noResultsText="Pas de résultat"
					s="Commencez votre saisie"
					options={options}
					multi={multi}
					{...input}
					loadOptions={loadOptions}
					onChange={value => input.onChange(value)}
					onBlur={() => input.onBlur(input.value)}
					{...(touched && error ? { invalid: true } : {})}
				/>

				{touched && error && (
					<FormFeedback
						className="help-block"
						style={{ display: "block" }}
					>
						{error}
					</FormFeedback>
				)}

				{help && <FormText className="help-block">{help}</FormText>}
			</Col>
		</FormGroup>
	);
};

FieldSelectAsync.defaultProps = {
	className: null,
	input: {},
	reverted: false,
	label: null,
	type: null,
	help: null,
	name: null,
	placeholder: null,
	id: null,
	disabled: false,
	meta: {
		touched: false,
		error: null,
	},
};

FieldSelectAsync.propTypes = {
	className: PropTypes.string,
	input: PropTypes.object,
	label: PropTypes.string,
	type: PropTypes.string,
	help: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	reverted: PropTypes.bool,
	meta: PropTypes.object,
};

export default FieldSelectAsync;
