import React, {Component} from 'react';
import {Alert, Form} from 'reactstrap';
import {Field, reduxForm} from 'redux-form'
import {ModalBody, ModalFooter} from 'reactstrap';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';

import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {getLocal} from "../../../../reducers/params";
import {WhFieldSelect, WhValidator, WhFieldTxt} from "../../../../components/Form/whFields";
import {
    getCmsStatus,
} from "../../../../reducers/params";

class SubjectForm extends Component {
    state = {
        open: false
    };

    render = () => {
        const {handleSubmit, submitting, cmsStatus, error, locale} = this.props;

        const style = locale ? locale == 'en' ? {
            backgroundSize: "20px",
            backgroundImage: `url("/img/en.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        } : {
            backgroundSize: "20px",
            backgroundImage: `url("/img/fr.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        } : {
            backgroundSize: "20px",
            backgroundImage: `url("/img/fr.png")`,
            backgroundRepeat: " no-repeat ",
            backgroundPosition: "right top",
        };

        return (

            <Form onSubmit={handleSubmit} className="form-horizontal">

                <ModalBody>

                    <Field
                        label="Sujet :"
                        name="title"
                        component={WhFieldTxt}
                        type="text"
                        style={style}
                    />

                    <Field
                        label="Email de redirection :"
                        name="email"
                        component={WhFieldTxt}
                        type="text"
                        style={style}
                    />

                    <Field
                        label="Statut de publication :"
                        name="status"
                        required
                        validate={[WhValidator.required]}
                        component={WhFieldSelect}
                        type="select"
                        options={cmsStatus}
                        style={style}
                    />

                    {error && (
                        <Alert color="danger">
                            <div dangerouslySetInnerHTML={{ __html: error }} />
                        </Alert>
                    )}

                </ModalBody>

                <ModalFooter>

                    <LaddaButton
                        className="btn btn-success btn-ladda"
                        loading={submitting}
                        data-style={EXPAND_LEFT}
                        type={'submit'}
                    >
                        <i className="fa fa-save"> </i>
                        &nbsp;Enregistrer
                    </LaddaButton>

                </ModalFooter>

            </Form>

        );

    }
}

SubjectForm.propTypes = {
    error: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    local: PropTypes.string
};
SubjectForm.defaultProps = {
    error: "",
};

const mapStateToProps = (state) => ({
    local: getLocal(state),
    cmsStatus: getCmsStatus(state),
});

SubjectForm = connect(
    mapStateToProps
)(SubjectForm);

export default reduxForm({
    form: 'SubjectForm'
})(SubjectForm);