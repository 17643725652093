import React, { Component } from "react";
import Loading from "../../../components/Loading";
import Form from "./Form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { SubmissionError } from "redux-form";
import { bindActionCreators } from "redux";
import merge from "lodash/merge";
import washJson from "../../../utils/washJson";
import {
	getLocal,
	getLocals,
	setLocal,
	getDefaultLocal,
} from "../../../reducers/params";
import { toast } from "react-toastify";
import Locals from "../../../components/Locals";
import * as pageService from "../../../services/cmsPage";

import tplt from "../../../constants/tplt/pages";
import {getUser} from "../../Authentication/redux";
import {ROLE_ADMIN} from "../../../constants/roles";

class Page extends Component {
	state = {
		data: {},
		loaded: false,
	};

	componentDidMount = () => {
		this.load();
	};

	load = async () => {
		const { id } = this.props.match.params;

		const urlParams = new URLSearchParams(window.location.search);
		const locale = urlParams.get('locale');

		if (id) {
			let data = await pageService.view(id, locale);

			data = merge(JSON.parse(JSON.stringify(tplt[data.template])), data);

			this.setState({
				data,
				loaded: true,
			});
		} else {
			this.setState({
				loaded: true,
				data: tplt["home"],
			});
		}
	};

	submit = values => {
		const edit = !values.id ? pageService.create : pageService.update;
		const caseForm = !values.id ? "Création réussie" : "Modification réussie";

		const urlParams = new URLSearchParams(window.location.search);
		const locale = urlParams.get('locale');

		(this.props.user.roles.includes('ROLE_RGPD') && !this.props.user.roles.includes('ROLE_ADMIN')) && delete values.parent;

		return edit(values, locale)
			.then(() => {
				toast.success(caseForm);
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	render() {
		const { data } = this.state;
		const { local, defaultLocal } = this.props;

		return (
			<div className="animated fadeIn">
				<h1>Editer une page</h1>
				{!this.state.loaded && <Loading />}
				{this.state.loaded && (
					<div>
						<Form
							ref="form"
							onSubmit={this.submit}
							pageId={this.props.match.params}
							defaultLocal={defaultLocal}
							initialValues={data}
						/>
					</div>
				)}
			</div>
		);
	}
}

Page.defaultProps = {
	local: null,
	defaultLocal: null,
};

Page.propTypes = {
	local: PropTypes.string,
	defaultLocal: PropTypes.string,
	locals: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
	local: getLocal(state),
	defaultLocal: getDefaultLocal(state),
	locals: getLocals(state),
	user: getUser(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setLocal,
		},
		dispatch,
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Page);
