import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as reduxFormReducer } from "redux-form";
import { userReducer, USER_RK } from "../modules/Authentication/redux";
import { paramsReducer, PARAMS_RK } from "./params";

const reducers = {
	routing: routerReducer,
	form: reduxFormReducer,
	[USER_RK]: userReducer,
	[PARAMS_RK]: paramsReducer
};

export default combineReducers(reducers);
