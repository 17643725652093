const society01Container = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Titre ",
        fields: {
            background: {
                admin: {
                    template: "BlocMedia",
                    type: "tplt-picture",
                },
            },
            title: {
                admin: {
                    template: "BlocTitle",
                    content:
                        '<h1 class="text-center size-huge weight-bold lh-10"> Découvrer les<br>fondations de<br>Castor Network</h1>',
                },
            },
        },
    },
};

export const column = {
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Colonne",
        fields: {
            title: {
                admin: {
                    template: "BlocText1",
                    content: '<span class="weight-light size-big">Des fondations solides</span>',
                },
            },
            subtitle: {
                admin: {
                    template: "BlocText1",
                    content: '<span class="weight-bold size-big mt-10">Découvrez Castor Network </span>',
                },
            },
        },
    },
};

export const threeColumns = {
    admin: {
        template: "BlocCollection",
        title: "Bloc trois colonnes",
        type: "tplt-slider-horizontal",
        add: true,
        editable: true,
        defaultTemplates: [column],
    },
};

const society01 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/society/society01.html.twig",
    admin: {
        template: "BlocCollection",
        title: "Bloc 1",
        editable: true,
    },
    contents: [society01Container, threeColumns],
};

const society02 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/society/society02.html.twig",
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Des fondations solides !",
        fields: {
            background: {
                admin: {
                    template: "BlocMedia",
                    type: "tplt-picture",
                },
            },
            paragraphe: {
                admin: {
                    template: "BlocText1",
                    content:
                        ' <h2 class=" size-title weight-light">Des fondations solides !</h2> <p class=" size-title weight-bold">Découvrez Castor Network</p><p class="size-soHuge weight-bold mt-50 lh-10">2018</p><p class="size-medium weight-light w-70 lh-15">marque le début de notre histoire, Denique Antiochensis ordinis vertices sub uno elogio iussit occidi ideo efferatus, quod ei celebrari vilitatem intempestivam urgenti,</p><p class="size-medium weight-bold mt-10 w-70 lh-15">Mais pourquoi un Castor ? C’est à l\'image de son talent : c’est un bâtisseur hors pair! Toujours dans l’action, il symbolise la puissance et la fierté du travail accompli. Fidèle à son équipe, le Castor est symbole d’harmonie entre les êtres et le travail.</p>',
                },
            },
        },
    },
};

const society03 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/society/society03.html.twig",
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Notre ambition ?",
        fields: {
            paragraphe: {
                admin: {
                    template: "BlocText1",
                    content:
                        '  <h2 class=" size-title weight-light">Notre ambition ?</h2> <p class=" size-title weight-bold">Mettre en relation des experts !</p><i class="fas fa-quote-right mt-50 size-big"></i> <p class="size-subtitle weight-light lh-13">Nous sommes des professionnels de l’ingénierie et des ressources humaines. La création de Castor-Network fusionne ces deux expertises en mettant en relation des Clients avec des freelances qualifiés. Notre objectif est de donner accès à des missions d’envergures. Le principe « win-win » (traduction : gagnant-gagnant) est notre motivation.</p><p class="size-verybig weight-bold mt-50">Le principe "win-win"</p><p class="size-verybig weight-light mt-5">(traduction: gagnant-gagnant)</p><p class="size-verybig weight-bold mt-5">est notre motivation</p>',
                },
            },
        },
    },
};

const society04Container = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Titre ",
        fields: {
            title: {
                admin: {
                    template: "BlocText1",
                    content:
                        '<h2 class=" size-title weight-light">Nos valeurs ...</h2> <p class=" size-title weight-bold">Pour mériter votre confiance !</p>',
                },
            },
        },
    },
};

export const tab = {
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Onglet",
        fields: {
            title: {
                admin: {
                    template: "BlocText1",
                    content: 'Human',
                },
            },
            content: {
                admin: {
                    template: "BlocText1",
                    content: "Relation humaine,<br />partenariat,<br />développement personnel",
                },
            },
        },
    },
};

export const tabs = {
    admin: {
        template: "BlocCollection",
        title: "Onglets",
        type: "tplt-slider-horizontal",
        add: true,
        editable: true,
        defaultTemplates: [tab],
    },
};

const society04 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/society/society04.html.twig",
    admin: {
        template: "BlocCollection",
        title: "Bloc 1",
        editable: true,
    },
    contents: [society04Container, tabs],
};

/**
 * EXPORT
 */
export default {
    template: "society",
    name: "Notre societé",
    editable: true,
    add: false,
    seo: {
        robots: "index,follow",
    },
    builder: [society01, society02, society03, society04],
};
