import React from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
	Col,
	FormGroup,
	Label,
	Input,
	FormFeedback,
	FormText,
} from "reactstrap";
import PropTypes from "prop-types";
import "react-select/dist/react-select.css";
import "react-datepicker/dist/react-datepicker.css";

export const FieldSelect = ({
	options,
	nullValue,
	help,
	valueField,
	valueName,
	input,
	label,
	multiple,
	required,
	meta: { touched, error },
}) => {
	const value = valueField ? valueField : "value";
	const name = valueName ? valueName : "name";

	return (
		<FormGroup row>
			<Col lg="12">
				{label && <Label>{label}</Label>}
				{required && (
					<span className="text-danger font-weight-bold">*</span>
				)}
			</Col>

			<Col lg={12}>
				<Input
					type="select"
					{...input}
					multiple={multiple}
					{...(touched && error ? { invalid: true } : {})}
				>
					{!required && !multiple && (
						<option value={null}>{nullValue}</option>
					)}
					{options.map((option, index) => (
						<option value={option[value]} key={index}>
							{option[name]}
						</option>
					))}
				</Input>

				{touched && error && (
					<FormFeedback className="help-block">{error}</FormFeedback>
				)}

				{help && <FormText className="help-block">{help}</FormText>}
			</Col>
		</FormGroup>
	);
};

FieldSelect.defaultProps = {
	className: null,
	input: {},
	valueField: null,
	valueName: null,
	reverted: false,
	label: null,
	type: null,
	help: null,
	name: null,
	placeholder: null,
	id: null,
	disabled: false,
	meta: {
		touched: false,
		error: null,
	},
};

FieldSelect.propTypes = {
	className: PropTypes.string,
	input: PropTypes.object,
	valueField: PropTypes.string,
	valueName: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	type: PropTypes.string,
	help: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	reverted: PropTypes.bool,
	meta: PropTypes.object,
};

export default FieldSelect;
