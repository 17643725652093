export const PARAMS_RK = "Params";
export const PARAMS_LOCALS_RK = "locals";
export const PARAMS_LOCAL_RK = "local";
export const PARAMS_DEFAULT_LOCAL_RK = "default";
export const PARAMS_CMS_STATUS = "cmsStatus";
export const PARAMS_SEO_ROBOTS = "seoRobots";
export const PARAMS_TEMPLATES = "templates";
export const PARAMS_CMSTPLTS = "cmsTplt";
export const PARAMS_ROLES = "ROLES";
export const PARAMS_CONTACT_TYPES = "CONTACT_TYPES";

export const SET_PARAMS = "SET_PARAMS";
export const SET_LOCAL = "SET_LOCAL";
export const SET_DEFAULT_LOCAL = "SET_DEFAULT_LOCAL";
export const SET_PARAMS_CMS_STATUS = "SET_PARAMS_CMS_STATUS";
export const SET_PARAMS_SEO_ROBOTS = "SET_PARAMS_SEO_ROBOTS";
export const SET_TEMPLATES = "SET_TEMPLATES";
