import React, {Component} from 'react';
import {Alert, Form} from 'reactstrap';
import {Field, reduxForm} from 'redux-form'
import {FieldTxt} from '../../components/Form/wh-field';
import {ModalBody, ModalFooter} from 'reactstrap';
import LaddaButton, {EXPAND_LEFT} from 'react-ladda';

import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {getLocal} from "../../reducers/params";
import { WhFieldSelect, WhValidator, WhFieldCkEditor, WhParseInt} from "../../components/Form/whFields";
import {
    getCmsStatus,
} from "../../reducers/params";

class TestimonyForm extends Component {
    state = {
        open: false
    };

    render = () => {
        const {local,error, handleSubmit, submitting, cmsStatus} = this.props;

        return (

            <Form onSubmit={handleSubmit} className="form-horizontal">

                <ModalBody>

                    <Field
                        label="Titre :"
                        name="title"
                        component={FieldTxt}
                        type="text"
                    />

                    <Field
                        label="Avis :"
                        name="subtitle"
                        component={FieldTxt}
                        type="text"
                        placeholder="L'avis du freelance"
                    />

                    <Field
                        label="Description :"
                        name="description"
                        component={FieldTxt}
                        type="textarea"
                    />

                    <Field
                        label="Nombre d'étoiles :"
                        name="star"
                        component={FieldTxt}
                        require
                        type="number"
                        parse={WhParseInt}
                    />

                    <Field
                        label="Prénom :"
                        name="firstName"
                        component={FieldTxt}
                        type="text"
                    />

                    <Field
                        label="Nom :"
                        name="lastName"
                        component={FieldTxt}
                        type="text"
                    />

                    <Field
                        label="Email :"
                        name="email"
                        component={FieldTxt}
                        type="text"
                    />

                    <Field
                        label="Téléphone :"
                        name="phone"
                        component={FieldTxt}
                        type="text"
                    />

                    <Field
                        label="Société :"
                        name="society"
                        component={FieldTxt}
                        type="text"
                    />

                    <Field
                        label="Métier :"
                        name="job"
                        component={FieldTxt}
                        type="text"
                    />

                    <Field
                        label="Langue ?"
                        name="locale"
                        component={WhFieldSelect}
                        options={[
                            {
                                value: "fr",
                                name: "Français"
                            },
                            {
                                value: "en",
                                name: "Anglais"
                            },
                        ]}
                    />

                    <Field
                        label="Statut de publication :"
                        name="status"
                        required
                        validate={[WhValidator.required]}
                        component={WhFieldSelect}
                        type="select"
                        options={cmsStatus}
                    />

                    <Field
                        label="Afficher sur l'accueil ?"
                        name="pushHome"
                        component={WhFieldSelect}
                        options={[
                            {
                                value: "false",
                                name: "Non"
                            },
                            {
                                value: "true",
                                name: "Oui"
                            },
                        ]}
                        required
                    />

                    <Field
                        label="Afficher sur la page engagements ?"
                        name="pushEngagements"
                        component={WhFieldSelect}
                        options={[
                            {
                                value: "false",
                                name: "Non"
                            },
                            {
                                value: "true",
                                name: "Oui"
                            },
                        ]}
                        required
                    />

                    {error && (
                        <Alert color="danger">
                            <div dangerouslySetInnerHTML={{ __html: error }} />
                        </Alert>
                    )}

                </ModalBody>

                <ModalFooter>

                    <LaddaButton
                        className="btn btn-success btn-ladda"
                        loading={submitting}
                        data-style={EXPAND_LEFT}
                        type={'submit'}
                    >
                        <i className="fa fa-save"> </i>
                        &nbsp;Enregistrer
                    </LaddaButton>

                </ModalFooter>

            </Form>

        );

    }
}

TestimonyForm.propTypes = {
    error: PropTypes.shape({}).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    local: PropTypes.string
};
TestimonyForm.defaultProps = {
    error: "",
};

const mapStateToProps = (state) => ({
    local: getLocal(state),
    cmsStatus: getCmsStatus(state),
});

TestimonyForm = connect(
    mapStateToProps
)(TestimonyForm);

export default reduxForm({
    form: 'TestimonyForm'
})(TestimonyForm);