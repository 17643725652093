import * as storage from '../storage';
import * as api from './api';

export const user = {
    id: null,
    name: null,
    email: null,
    roles: null,
};

export const login = (username, password) => {
    return api.login(username, password)
        .then((response) => {
            setJwtToken(response['token'])
        })
        .catch((exception) => {
            throw exception;
        });
};



export const setJwtToken = (jwtToken) => {
    return storage.save('jwt_token', jwtToken);
};

export const deleteJwtToken = () => {
    return storage.destroy('jwt_token');
};

export const logout = () => {
    deleteJwtToken();
    return Promise.resolve('disconnected');
};

export const getJwtToken = () => {
    return storage.get('jwt_token');
};

export const checkRole = (role) => {
    let res = false;

    user.roles.map((el) => {
        if (el === role) res = true;
    });

    return res;
};

export const isAuthenticated = () => {
    return api.me()
        .then((response) => {
            user.id = response.id;
            user.name = response.name;
            user.email = response.email;
            user.roles = response.roles;

            return Promise.resolve(response);
        })
        .catch(() => {
            return Promise.reject('disconnected');
        });
};