const API_BASE = "https://www.castor-network.com";
const API_API_BASE = "https://api.castor-network.com";

const MS_MAINTENANCE = "https://maintenance.castor-network.com"


const APICONFIG = {
  title: "Castor ::: admin",
  url: API_BASE,
  apiUrl: API_API_BASE,
  maintenance: MS_MAINTENANCE,
  apiAssetUrl: `/img`,
  assetUrl: `https://medias-castor.whatson-web.com/img`,
  icons: `/icomoon/demo.html`,
  ckeditorInstance: `/ckeditor/ckeditor.js`,
  finderParams: {
    url: `/api`,
    endPointMedia: "/media",
    assetUrl: `https://medias-castor.whatson-web.com/img`,
  },
  finderApiParams: {
    url: `/api`,
    endPointMedia: "/media",
    assetUrl: `https://medias-castor.whatson-web.com/img`,
  },
};
module.exports = APICONFIG;