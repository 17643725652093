import React from 'react';
import { Col, FormGroup, FormText, Label, InputGroup, FormFeedback, Input } from 'reactstrap';

import Select from 'react-select';
import 'react-select/dist/react-select.css';

export const SimpleInput = ({ placeholder, input, type, meta: { touched, error } }) => (
    <Input
        placeholder={placeholder}
        {...input}
        type={type}
        {...(touched && error ? { valid: false } : {})}
    />
);

export const FieldColor = ({
    require = false,
    id,
    input,
    label,
    help,
    meta: { touched, error },
}) => (
    <FormGroup row>
        <Col md="3">
            {label && <Label>{label}</Label>}
            {require && <span className="text-danger font-weight-bold">*</span>}
        </Col>
        <Col xs="12" md="9">
            <InputGroup>
                <Input
                    {...input}
                    id={id}
                    type="color"
                    {...(touched && error ? { valid: false } : {})}
                />

                {touched &&
                    error && (
                        <FormFeedback className="help-block" style={{ display: 'block' }}>
                            {error}
                        </FormFeedback>
                    )}
                {help && <FormText className="help-block">{help}</FormText>}
            </InputGroup>
        </Col>
    </FormGroup>
);
export const FieldTxtIcon = ({
    require = false,
    disabled,
    placeholder,
    bsSize,
    id,
    mask,
    prepend,
    append,
    input,
    label,
    type,
    help,
    meta: { touched, error },
}) => (
    <FormGroup row>
        <Col md="3">
            {label && <Label>{label}</Label>}
            {require && <span className="text-danger font-weight-bold">*</span>}
        </Col>
        <Col xs="12" md="9">
            <InputGroup>
                {prepend && (
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            {prepend.icon && <i className={prepend.icon} />}
                            {!prepend.icon && prepend}
                        </span>
                    </div>
                )}

                {!mask && (
                    <Input
                        {...input}
                        id={id}
                        bsSize={bsSize}
                        placeholder={placeholder}
                        disabled={disabled}
                        type={type}
                        {...(touched && error ? { valid: false } : {})}
                    />
                )}

                {append && (
                    <div className="input-group-append">
                        <span className="input-group-text">
                            {append.icon && <i className={append.icon} />}
                            {!append.icon && append}
                        </span>
                    </div>
                )}
                {touched &&
                    error && (
                        <FormFeedback className="help-block" style={{ display: 'block' }}>
                            {error}
                        </FormFeedback>
                    )}
                {help && <FormText className="help-block">{help}</FormText>}
            </InputGroup>
        </Col>
    </FormGroup>
);

export const FieldTxt = ({
    require = false,
    mask,
    input,
    label,
    type,
    help,
    bsSize,
    placeholder,
    id,
    disabled,
    meta: { touched, error },
}) => (
    <FormGroup row>
        <Col lg="12">
            {label && <Label>{label}</Label>}
            {require && <span className="text-danger font-weight-bold">*</span>}
        </Col>
        <Col lg={12}>
            {!mask && (
                <Input
                    {...input}
                    id={id}
                    bsSize={bsSize}
                    placeholder={placeholder}
                    disabled={disabled}
                    type={type}
                    {...(touched && error ? { valid: false } : {})}
                />
            )}

            {touched && error && <FormFeedback className="help-block">{error}</FormFeedback>}
            {help && <FormText className="help-block">{help}</FormText>}
        </Col>
    </FormGroup>
);

export const FieldCheckbox = ({ name, input, label, meta: { touched, error } }) => (
    <FormGroup row>
        <Col md="1">
            <input {...input} checked={input.value} type="checkbox" />
            {touched && error && <span className="error-txt">{error}</span>}
        </Col>

        <Col md="10">
            <label htmlFor={name}>{label}</label>
        </Col>
    </FormGroup>
);

export const FieldSelect = props => {
    const {
        nullValue,
        help,
        options,
        input,
        label,
        meta: { touched, error },
        multiple = false,
        required = true,
    } = props;
    return (
        <FormGroup row>
            <Col lg="12">
                {label && <Label>{label}</Label>}
                {required && <span className="text-danger font-weight-bold">*</span>}
            </Col>

            <Col lg={12}>
                <Input
                    type="select"
                    {...input}
                    multiple={multiple}
                    {...(touched && error ? { valid: false } : {})}
                >
                    {!required && !multiple && <option value={null}>{nullValue}</option>}
                    {options.map((option, index) => (
                        <option value={option.value} key={index}>
                            {option.name}
                        </option>
                    ))}
                </Input>

                {touched && error && <FormFeedback className="help-block">{error}</FormFeedback>}

                {help && <FormText className="help-block">{help}</FormText>}
            </Col>
        </FormGroup>
    );
};

export const FieldSelect2 = ({
    require = false,
    help,
    options,
    input,
    label,
    meta: { touched, error },
}) => (
    <FormGroup row>
        <Col lg={12}>
            {label && <Label>{label}</Label>}
            {require && <span className="text-danger font-weight-bold">*</span>}
        </Col>

        <Col lg={12}>
            <Select
                options={options}
                multi
                {...input}
                onChange={value => input.onChange(value.map(p => p.value))}
                onBlur={() => {
                    input.onBlur([...input.value]);
                }}
            />

            {touched && error && <FormFeedback className="help-block">{error}</FormFeedback>}

            {help && <FormText className="help-block">{help}</FormText>}
        </Col>
    </FormGroup>
);

export const FieldSelectAsync = ({
    help,
    options,
    multi,
    loadOptions,
    name,
    input,
    label,
    meta: { touched, error },
}) => (
    <FormGroup row>
        <Col md="3">{label && <Label>{label}</Label>}</Col>

        <Col xs="12" md="9">
            <Select.Async
                name={name}
                placeholder="Chercher"
                noResultsText="Pas de résultat"
                s="Commencez votre saisie"
                options={options}
                multi={multi}
                {...input}
                loadOptions={loadOptions}
                onChange={value => input.onChange(value)}
                onBlur={() => input.onBlur(input.value)}
                {...(touched && error ? { valid: false } : {})}
            />

            {touched &&
                error && (
                    <FormFeedback className="help-block" style={{ display: 'block' }}>
                        {error}
                    </FormFeedback>
                )}

            {help && <FormText className="help-block">{help}</FormText>}
        </Col>
    </FormGroup>
);

export const FieldNumeric = props => {
    const {
        require = false,
        labelSize = '12',
        inputSize = '12',
        min,
        max,
        step,
        input,
        label,
        bsSize,
        help,
        placeholder,
        id,
        meta: { touched, error },
    } = props;
    return (
        <FormGroup row>
            <Col md={labelSize}>
                {label && <Label>{label}</Label>}
                {require && <span className="text-danger font-weight-bold">*</span>}
            </Col>
            <Col xs="12" md={inputSize}>
                <Input
                    {...input}
                    id={id}
                    bsSize={bsSize}
                    placeholder={placeholder}
                    min={min}
                    max={max}
                    step={step}
                    type="number"
                    {...(touched && error ? { valid: false } : {})}
                />
                {touched && error && <FormFeedback className="help-block">{error}</FormFeedback>}
                {help && <FormText className="help-block">{help}</FormText>}
            </Col>
        </FormGroup>
    );
};
