import React, { Component } from "react";
import { toast } from "react-toastify";
import ApiConfig from "../../../constants/ApiConfig";
import EditPopup from "./PopupItem/PopupEdit";
import Items from "./Items";
import { Card, CardHeader, Button } from "reactstrap";
import * as menuService from "../../../services/cmsMenu";
import * as menuItemService from "../../../services/cmsMenuItem";

class Menu extends Component {
	state = { loaded: true, menu: false, id: null, items: {} };

	loadMenu = async id => {
		if (!id) id = this.state.id;

		this.setState({ loaded: false, id });

		const menu = await menuService.view(id);

		// on récupère uniquement les menu item nayant pas de parent, car ils sont dans les enfants
		const items = await menuItemService.search({
			filtered: [
				{ id: "menu.id", value: id },
				{ id: "parent[exists]", value: false },
			],
		});

		items["hydra:member"].sort((a, b) => (a.position > b.position) ? 1 : -1);

		items["hydra:member"].forEach( (item) => item.childrens.sort((a, b) => (a.position > b.position) ? 1 : -1))

		this.setState({ loaded: true, menu, items: items["hydra:member"] });
	};

	remove = async toDelete => {
		if (window.confirm("Etes vous sûre de vouloir supprimer cet item ?")) {
			try {
				await menuItemService.remove(toDelete);
				this.loadMenu();
				toast.success("Item supprimée");
			} catch (error) {
				alert(
					"Il existe des dépendances sur cette item, il ne peut donc être supprimé",
				);
			}
		}
	};

	position = async (position, el, index) => {
		let items;
		let prec;
		const max = this.state.items.length - 1;
		let precIndex = 0;

		switch (position) {
			case "up":
				if (index == 0) return;

				precIndex = index - 1;

				if (el.hasParent) {
					await menuItemService.parent(el.id).then(
						parent => items = parent.childrens
					);
				} else {
					items = this.state.items;
				}

				items.sort((a, b) => (a.position > b.position) ? 1 : -1)

				prec = items[precIndex];

				items[precIndex] = el;
				items[index] = prec;

				await menuItemService.update({
					id: el.id,
					position: precIndex,
				});

				await menuItemService.update({ id: prec.id, position: index });

				this.loadMenu();

				break;

			case "down":
				if (index == max) return;

				precIndex = index + 1;

				if (el.hasParent) {
					await menuItemService.parent(el.id).then(
						parent => items = parent.childrens
					);
				} else {
					items = this.state.items;
				}

				items.sort((a, b) => (a.position > b.position) ? 1 : -1)

				prec = items[precIndex];

				items[precIndex] = el;
				items[index] = prec;

				await menuItemService.update({
					id: el.id,
					position: precIndex,
				});
				await menuItemService.update({ id: prec.id, position: index });

				this.loadMenu();

				break;
		}
	};

	newData = data => {
		this.loadMenu();
	};

	render = () => {
		const { menu, items } = this.state;

		if (!menu) return <div />;

		return (
			<div className="animated fadeIn">
				<EditPopup
					ref={"editPopup"}
					newData={this.newData}
					menu={menu}
				/>

				<Card>
					<CardHeader>
						<i className="fa fa-align-justify" /> {menu.title}
						<div className="card-actions">
							<Button
								onClick={() => this.refs.editPopup.handleOpen()}
							>
								<i className={"fa fa-plus"} /> Créer
							</Button>
							<a
								href={`${ApiConfig.helpAdmin}/cms/pages.html`}
								target={"_blank"}
							>
								<i className={"fa fa-question"} />
							</a>
						</div>
					</CardHeader>

					<Items
						newData={this.newData}
						items={items}
						remove={this.remove}
						position={this.position}
						menu={menu}
					/>
				</Card>
			</div>
		);
	};
}

export default Menu;
