import {ROLE_ADMIN, ROLE_RGPD} from "../../../constants/roles";

export default {
	items: [
		{
			name: "CMS",
			roles: ROLE_ADMIN,
			icon: "fa fa-edit",
			children: [
				{
					name: "Pages",
					url: "/pages",
					roles: ROLE_ADMIN,
				},
				{
					name: "Menus",
					url: "/menus",
					roles: ROLE_ADMIN,
				},
				{
					name: "Sujets de contact",
					url: "/sujets-contact",
					roles: ROLE_ADMIN,
				},
			],
		},
		{
			name: "Gestion des Univers",
			roles: ROLE_ADMIN,
			icon: '',
			children: [
				{
					name: "Gestion des Secteurs",
					url: "/branches",
					roles: ROLE_ADMIN,
					icon: 'fa fa-address-card'
				},
				{
					name: "Gestion des Univers",
					url: "/univers",
					roles: ROLE_ADMIN,
					icon: 'fa'
				},
				{
					name: "Gestion des Sous Secteurs",
					url: "/sous-univers",
					roles: ROLE_ADMIN,
					icon: 'fa'
				},
				{
					name: "Gestion des Pages Métiers",
					url: "/jobs",
					roles: ROLE_ADMIN,
					icon: 'fa'
				},
				{
					name: "Gestion des News",
					url: "/news",
					roles: ROLE_ADMIN,
					icon: 'fa'
				},
			]
		},
		{
			name: "Tags",
			roles: ROLE_ADMIN,
			icon: "fa fa-tag",
			children: [
				{
					name: "Tags validés",
					url: "/tags/published",
					roles: ROLE_ADMIN,
				},
				{
					name: "Tags en attente",
					url: "/tags/waiting",
					roles: ROLE_ADMIN,
				},
				{
					name: "Tags avec une page associée",
					url: "/tags/univers",
					roles: ROLE_ADMIN,
				},
			],
		},
		{
			name: "Partenaires",
			roles: ROLE_ADMIN,
			icon: "fa fa-building",
			children: [
				{
					name: "Partenaires",
					url: "/partenaires",
					roles: ROLE_ADMIN,
				},
				{
					name: "Avantages",
					url: "/avantages",
					roles: ROLE_ADMIN,
				},
			],
		},
		{
			name: "Membres de l'équipe",
			url: "/membres",
			roles: ROLE_ADMIN,
			icon: 'fa fa-user'
		},
		{
			name: "Gestion des témoignages",
			url: "/temoignages",
			roles: ROLE_ADMIN,
			icon: 'fa fa-question'
		},
		{
			name: "Gestion des utilisateurs",
			roles: ROLE_ADMIN,
			url: "/users",
			icon: 'fa fa-user'
		},
		{
			name: "Gestion des paramètres",
			roles: ROLE_ADMIN,
			url: "/parametres",
			icon: 'fa fa-wrench'
		},
    {
      name: "Maintenance",
      roles: ROLE_ADMIN,
      url: "/maintenance",
      icon: 'fa fa-wrench'
    },
	],
};
