import React, { Component } from "react";
import WHTable from "../../../components/WHTable";
import ApiConfig from "../../../constants/ApiConfig";
import CreateEditPopup from "./Popup/CreateEditPopup";
import { Card, CardHeader, Button } from "reactstrap";
import * as seoService from "../../../services/seo";

class Seos extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			col: [],
			pages: null,
			loaded: true,
		};
		this.load = this.load.bind(this);
		this.columns = this.columns.bind(this);
		this.remove = this.remove.bind(this);
		this.newData = this.newData.bind(this);
	}

	remove(toDelete) {
		if (window.confirm("Êtes-vous sûr de vouloir supprimer cet item ?")) {
			this.setState({
				loaded: false,
			});

			seoService
				.remove(toDelete)
				.then(() => {
					let lst = [...this.state.data];

					let index = lst.findIndex(el => el.id === toDelete);

					if (index > -1) {
						lst.splice(index, 1);

						this.setState({
							data: lst,
							loaded: true,
						});
					}
				})
				.catch(() => {
					alert(
						"Il existe des dépendances cet item, il ne peut donc être supprimé",
					);
				});
		}
	}

	columns() {
		return [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				Cell: row => (
					<div>
						<Button
							color={"success"}
							onClick={() =>
								this.refs.createEditPopup.handleOpen(row.value)
							}
						>
							Details
						</Button>
						<Button
							color={"danger"}
							onClick={() => this.remove(row.value)}
						>
							<i className={"fa fa-trash"} />
						</Button>
					</div>
				),
				maxWidth: 120,
			},
			{
				Header: "Id",
				accessor: "entityId",
			},
			{
				Header: "Title",
				accessor: "metaTitle",
			},
			{
				Header: "Robots",
				accessor: "robots",
			},
		];
	}

	load(state, instance) {
		this.setState({ loaded: false });

		return seoService.search(state).then(data => {
			this.setState({ data: [] }, () => {
				this.setState({
					data: data["hydra:member"],
					pages: Math.ceil(data["hydra:totalItems"] / state.pageSize),
					loaded: true,
				});
			});
		});
	}

	newData(data) {
		let lst = [...this.state.data];

		let index = lst.findIndex(el => el.id === data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState({
			data: lst,
		});
	}

	render() {
		const { history, match } = this.props;
		const { data, pages, loading } = this.state;

		return (
			<div className="animated fadeIn">
				<CreateEditPopup
					ref={"createEditPopup"}
					newData={this.newData}
				/>

				<Card>
					<CardHeader>
						<i className="fa fa-align-justify" /> Gestion des
						balises pour Seo
						<div className="card-actions">
							<Button
								onClick={() =>
									this.refs.createEditPopup.handleOpen()
								}
							>
								<i className={"fa fa-plus"} /> Créer
							</Button>
							<a
								href={`${ApiConfig.helpAdmin}/cms/post.html`}
								target={"_blank"}
							>
								<i className={"fa fa-question"} />
							</a>
						</div>
					</CardHeader>

					<WHTable
						data={data}
						pages={pages}
						columns={this.columns()}
						filterable={true}
						onFetchData={this.load}
						loading={!this.state.loaded}
					/>
				</Card>
			</div>
		);
	}
}

export default Seos;
