import React, { Component } from "react";
import { Nav, NavItem, NavbarToggler, NavbarBrand, NavLink } from "reactstrap";
import HeaderDropdownAccount from "./HeaderDropdownAccount";
import MediaLibrary from "./MediaLibrary";

const logo = `url(img/logo.png)`;

class MyFooter extends Component {
	sidebarToggle(e) {
		e.preventDefault();
		document.body.classList.toggle("sidebar-hidden");
	}

	mobileSidebarToggle(e) {
		e.preventDefault();
		document.body.classList.toggle("sidebar-mobile-show");
	}

	render() {
		return (
			<header className="app-header navbar">
				<NavbarToggler
					className="d-lg-none"
					onClick={this.mobileSidebarToggle}
				>
					<span className="navbar-toggler-icon" />
				</NavbarToggler>

				<NavbarBrand href="#" style={{ backgroundImage: logo, backgroundSize: '150px' }} />

				<NavbarToggler
					className="d-md-down-none sidebar-minimizer h-100 b-r-1"
					onClick={this.sidebarToggle}
				>
					<span className="navbar-toggler-icon" />
				</NavbarToggler>

				<Nav navbar>
					<NavItem className="d-md-down-none">
						<NavLink href="#/" style={{ padding: "0 20px" }}>
							<i className="fa fa-home" />
							&nbsp;Mon Dashboard
						</NavLink>
					</NavItem>
				</Nav>

				<Nav className="ml-auto" navbar>
					<MediaLibrary />
					<HeaderDropdownAccount />
				</Nav>
			</header>
		);
	}
}

export default MyFooter;
