const concept01 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/concept/concept01.html.twig",
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Ne cherchez plus",
        fields: {
            title: {
                admin: {
                    template: "BlocTitle",
                    content:
                        '<h1 class="text-center size-huge weight-bold title mt-90 lh-9">Ne cherchez-plus, les projets technologiques d\'envergures sont ici !</h1>',
                },
            },
            subtitle: {
                admin: {
                    template: "BlocText1",
                    content:
                        '<p class="text-center size-big weight-light">Scrollez et découvrez</p><p class="text-center size-big weight-bold mt-15">Les différentes étapes de fonctionnement Castor</p>',
                },
            },
        },
    },
};

const concept02 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/concept/concept02.html.twig",
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Philosophie",
        fields: {
            background: {
                admin: {
                    template: "BlocMedia",
                    type: "tplt-picture",
                },
            },
            paragraphe: {
                admin: {
                    template: "BlocText1",
                    content:
                        '<h2 class=" size-title weight-bold">Notre philosophie</h2> <p class=" size-big weight-bold mt-30 lh-16"> On vous accompagne de votre inscription à votre paiement. C’est vous qui décidez. adhibens modum orientis latera cuncta vexabat nec honoratis parcens nec urbium primatibus nec plebeiis. </p><p class=" size-big weight-light mt-30 lh-16"> Denique Antiochensis ordinis vertices sub uno elogio iussit occidi ideo efferatus, quod ei celebrari vilitatem intempestivam urgenti, cum inpenderet inopia, gravius rationabili responderunt; et perissent ad unum ni comes orientis tunc Honoratus fixa constantia restitisset. </p>',
                },
            },
        },
    },
};

export const concept03Container = {
    admin: {
        template: "BlocContainer",
        title: "Bloc 2 en-tête",
        type: "tplt-paragraph",
        fields: {
            paragraphe: {
                admin: {
                    template: "BlocText1",
                    type: "tplt-paragraph",
                    content: '<h2 class="size-title weight-light">Le choix de la différence !</h2> <h2 class="size-title weight-bold">Pourquoi choisir Castor Network ?</h2>',
                },
            },
            button: {
                admin: {
                    template: "BlocText1",
                    type: "tplt-paragraph",
                    content: "Découvrir notre équipe"
                }
            }
        },
    },
};

export const textWithIcon = {
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Element",
        fields: {
            icon: {
                admin: {
                    template: "BlocText1",
                    content: ' <div><span class="icon-technpartner size-verybig"></span></div> ',
                },
            },
            title: {
                admin: {
                    template: "BlocText1",
                    content: ' <span class="size-medium weight-bold mt-15">Accés à une multitudes de missions techniques</span> ',
                },
            },
            subtitle: {
                admin: {
                    template: "BlocText1",
                    content: '<span class="size-big weight-light lh-20 mt-15 w-70">Réaliser des missions d’envergures et sécurisantes à forte valeur ajoutée technologique.</span>'
                }
            }
        },
    },
};

export const threeTextWithIcons = {
    admin: {
        template: "BlocCollection",
        title: "Bloc trois colonnes",
        type: "tplt-slider-horizontal",
        add: true,
        editable: true,
        defaultTemplates: [textWithIcon],
    },
};

const concept03 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/concept/concept03.html.twig",
    admin: {
        template: "BlocCollection",
        title: "Le choix de la différence !",
        editable: true,
    },
    contents: [concept03Container, threeTextWithIcons],
};

/**
 * EXPORT
 */
export default {
    template: "concept",
    name: "Notre Concept",
    editable: true,
    add: false,
    seo: {
        robots: "index,follow",
    },
    builder: [concept01, concept03],
};
