import React from "react";
import "react-dates/initialize";
import { Col, Label, FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import uniqid from "uniqid";

export const FieldCheckbox = ({
	className,
	input,
	label,
	id,
	meta: { touched, error },
}) => {
	const newId = id ? id : uniqid();
	if (touched && error) console.log(error, input.name);
	return (
		<FormGroup row className={className} check>
			<Label check>
				<input
					{...input}
					checked={input.value}
					id={newId}
					type="checkbox"
				/>{" "}
				{label}
				{touched && error && <span className="error-txt">{error}</span>}
			</Label>
		</FormGroup>
	);
};

FieldCheckbox.defaultProps = {
	className: null,
	input: {},
	label: null,
	type: null,
	help: null,
	name: null,
	placeholder: null,
	id: null,
	disabled: false,
	meta: {
		touched: false,
		error: null,
	},
};

FieldCheckbox.propTypes = {
	className: PropTypes.string,
	input: PropTypes.object,
	label: PropTypes.string,
	type: PropTypes.string,
	help: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	meta: PropTypes.object,
};

export default FieldCheckbox;
