import React, { Component } from "react";
import {
	Field,
	FormSection,
	reduxForm,
	FieldArray,
	change,
	getFormValues,
} from "redux-form";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { WhFieldTxt, WhFieldCkEditor, WhFieldFile, WhFieldSelect } from "../..";
import { Form, ModalBody, ModalFooter, Alert } from "reactstrap";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";

class FormMedia extends Component {
	render() {
		const {
			error,
			handleSubmit,
			submitting,
			currentValues,
			type,
		} = this.props;

		return (
			<Form className="form-vertical">
				<ModalBody>
					{currentValues.admin &&
						currentValues.admin.type &&
						currentValues.admin.type == "tplt-video" && (
							<Field
								label={false}
								name="media"
								component={WhFieldTxt}
								type="textarea"
								placeholder="Code embed"
							/>
						)}

					{currentValues.admin &&
						currentValues.admin.type &&
						currentValues.admin.type == "tplt-document" && (
							<Field
								label={false}
								name="media"
								component={WhFieldFile}
								type="text"
								placeholder="Nom du bloc"
							/>
						)}

					{(!currentValues.admin ||
						!currentValues.admin.type ||
						currentValues.admin.type == "tplt-picture") && (
						<Field
							label={false}
							name="media"
							component={WhFieldFile}
							type="text"
							placeholder="Nom du bloc"
						/>
					)}

					{error && (
						<Alert color="danger">
							<div dangerouslySetInnerHTML={{ __html: error }} />
						</Alert>
					)}
				</ModalBody>
				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						data-style={EXPAND_LEFT}
						onClick={handleSubmit}
						type="button"
					>
						<i className="fa fa-save" /> Enregistrer{" "}
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

const formName = "formFormMedia";

const rxf = reduxForm({ form: formName })(FormMedia);

FormMedia.defaultProps = {
	currentValues: {},
};
FormMedia.propTypes = {
	currentValues: PropTypes.shape({}),
};

export const mapStateToProps = state => ({
	currentValues: getFormValues(formName)(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({ change }, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(rxf);
