const contact01 = {
	tplt: "/page/contact/contact01.html.twig",
	admin: {
		editable: false,
		add: false,
		template: "BlocContainer",
		title: "Contact",
		fields: {
            background: {
                admin: {
                    template: "BlocMedia",
                    type: "tplt-picture",
                },
            },
			title: {
				admin: {
					template: "BlocTitle",
					content:
						'Les questions récurrentes',
				},
			},
			subtitle: {
				admin: {
					template: "BlocTitle",
                    content: "Avant de nous contacter, fouillez dans notre FAQ, vous trouverez surement une réponse à vos questions !",
				},
			},
			formTitle: {
				admin: {
					template: "BlocTitle",
                    content: "Vous avez d'autres questions ?",
				},
			},
		},
	},
};

const contact02Container = {
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Titre ",
        fields: {
            title: {
                admin: {
                    template: "BlocTitle",
                    content:
                        'Les questions récurrentes',
                },
            },
        },
    },
};

export const faq = {
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "FAQ",
        fields: {
            question: {
                admin: {
                    template: "BlocTitle",
                    content: 'Qu’est-ce que Castor Network ?',
                },
            },
            answer: {
                admin: {
                    template: "BlocText1",
                    content: 'Nihil est enim virtute amabilius, nihil quod magis adliciat ad diligendum',
                },
            },
        },
    },
};

export const faqCollection = {
    admin: {
        template: "BlocCollection",
        title: "Liste FAQ",
        type: "tplt-slider-horizontal",
        add: true,
        editable: true,
        defaultTemplates: [faq],
    },
};

const contact02 = {
    tplt: "/page/contact/contact02.html.twig",
    admin: {
        template: "BlocCollection",
        title: "Bloc 1",
        editable: true,
    },
    contents: [contact02Container, faqCollection],
};

export default {
	template: "contact",
	name: "Page Contact",
	editable: false,
	add: false,
	seo: {
		robots: "index,follow",
	},
	builder: [contact01, contact02],
};
