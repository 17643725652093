import React, { Component } from "react";
import Loading from "../../../components/Loading";
import PostForm from "./PostForm";

import { SubmissionError } from "redux-form";

import { toast } from "react-toastify";
import * as postService from "../../../services/universe/post";

class PostView extends Component {
	state = {
		data: {},
		loaded: false,
	};

	componentDidMount = () => {
		this.load();
	};

	load = async () => {

		const { id } = this.props.match.params;

		const urlParams = new URLSearchParams(window.location.search);
		const locale = urlParams.get('locale') ? urlParams.get('locale') : 'fr';

		if (id) {
			let data = await postService.view(id, locale);

			data.activitySectors = data.activitySectors.map( val => ({ id: val.id, value: val.id, label: val.name }))

			this.setState({
				data,
				loaded: true,
				locale
			});
		} else {
			this.setState({
				loaded: true,
				locale
			});
		}
	};

	submit = values => {
		const edit = !values.id ? postService.create : postService.update;
		const caseForm = !values.id ? "Création réussie" : "Modification réussie";

		const urlParams = new URLSearchParams(window.location.search);
		const locale = urlParams.get('locale');

		// values.tag = values.tag['@id'];

		return edit(values, locale)
			.then(() => {
				this.props.history.push("/news");
				toast.success(caseForm);
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	render() {
		const { data, locale } = this.state;

		return (
			<div className="animated fadeIn">
				{!this.state.loaded && <Loading />}
				{this.state.loaded && (
					<div>
						<PostForm
							ref="form"
							onSubmit={this.submit}
							initialValues={data}
							locale={locale}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default PostView;
