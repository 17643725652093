import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import FormPopup from "./FormRequest";

class PopupRequest extends Component {
	state = {
		open: false,
		data: {},
		index: null,
		loaded: true,
	};

	handleOpen = (data = {}) => {

		const updatedData = {
			...data,
			filter: {...data.filters}
		};

		updatedData.filters = JSON.stringify(data.filters);

		this.setState({
			data:updatedData,
			open: true,
		});
	};

	handleClose = () => {
		this.setState({ open: false, data: {} });
	};

	submit = data => {
		const { onChange } = this.props;
		data.filters = JSON.parse(data.filters);
		onChange(data);
		this.setState({ open: false });
	};

	render() {
		const { data } = this.state;

		return (
			<Modal isOpen={this.state.open} fade={false} size="xs">
				<ModalHeader toggle={() => this.handleClose()}>
					Editer de la requête
				</ModalHeader>

				<FormPopup onSubmit={this.submit} initialValues={data} />
			</Modal>
		);
	}
}

export default PopupRequest;
