import React, { Component } from "react";
import { Form, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Field, reduxForm, getFormValues } from "redux-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getLocal } from "../../../../reducers/params";
import {
	WhFieldTxt,
	WhFieldCheckbox,
	WhFieldSelectAsync,
} from "../../../../components/Form/whFields";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";
import * as pageService from "../../../../services/cmsPage";

class FormEdit extends Component {
	loadParents = input =>
		pageService
			.search({ filtered: [{ id: "title", value: input }] })
			.then(data => {
				const lst = data["hydra:member"].map(el => {
					return { id: el.id, label: `${el.title}` };
				});
				return { options: lst };
			});

	render() {
		const {
			error,
			handleSubmit,
			submitting,
			currentValues,
			locale
		} = this.props;

		const style = locale ? locale == 'en' ? {
			backgroundSize: "20px",
			backgroundImage: `url("/img/en.png")`,
			backgroundRepeat: " no-repeat ",
			backgroundPosition: "right top",
		} : {
			backgroundSize: "20px",
			backgroundImage: `url("/img/fr.png")`,
			backgroundRepeat: " no-repeat ",
			backgroundPosition: "right top",
		} : {
			backgroundSize: "20px",
			backgroundImage: `url("/img/fr.png")`,
			backgroundRepeat: " no-repeat ",
			backgroundPosition: "right top",
		};

		return (
			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>
					<Field
						label="Titre : "
						name="title"
						component={WhFieldTxt}
						type="text"
						placeholder="Saisir un titre"
						bsSize="lg"
						style={style}
					/>

					<Field
						label="Lien externe"
						name="external"
						component={WhFieldCheckbox}
					/>

					{!currentValues.external && (
						<Field
							label="Page liée :"
							name="page"
							component={WhFieldSelectAsync}
							type="select"
							loadOptions={this.loadParents}
						/>
					)}

					{currentValues.external && (
						<Field
							label="Url : "
							name="url"
							component={WhFieldTxt}
							type="text"
							placeholder="Saisir une url"
							style={style}
						/>
					)}

					{error && (
						<Alert color="danger">
							<div
								dangerouslySetInnerHTML={{
									__html: error,
								}}
							/>
						</Alert>
					)}
				</ModalBody>
				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type="submit"
					>
						<i className="fa fa-save" /> Enregistrer{" "}
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

const formName = "FormMenuItem";

const rxf = reduxForm({ form: formName })(FormEdit);

FormEdit.defaultProps = {
	currentValues: {},
};
FormEdit.propTypes = {
	currentValues: PropTypes.shape({}),
};

export const mapStateToProps = state => ({
	currentValues: getFormValues(formName)(state),
	local: getLocal(state),
});

export default connect(mapStateToProps)(rxf);
