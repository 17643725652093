import React, { Component } from "react";
import { Form, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { WhFieldTxt } from "../../../../components/Form/whFields";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";

class UserFormPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	render() {
		const { error, handleSubmit, submitting } = this.props;

		return (
			<Form onSubmit={handleSubmit} className="form-vertical">
				<ModalBody>
					<Field
						label="Titre : "
						name="title"
						component={WhFieldTxt}
						type="text"
						placeholder="Saisir un titre"
						bsSize="lg"
					/>

					<Field
						label="Slug : "
						name="slug"
						component={WhFieldTxt}
						type="text"
						placeholder="un nom technique"
						bsSize="lg"
					/>

					{error && (
						<Alert color="danger">
							<div
								dangerouslySetInnerHTML={{
									__html: error,
								}}
							/>
						</Alert>
					)}
				</ModalBody>
				<ModalFooter>
					<LaddaButton
						className="btn btn-success btn-ladda"
						loading={submitting}
						data-style={EXPAND_LEFT}
						type="submit"
					>
						<i className="fa fa-save" /> Enregistrer{" "}
					</LaddaButton>
				</ModalFooter>
			</Form>
		);
	}
}

UserFormPopup = reduxForm({
	form: "UserFormPopup",
})(UserFormPopup);

export default UserFormPopup;
