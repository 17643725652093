import React, { Component } from "react";
import CreateEditPopup from "./CreateEditPopup";
import WHTable from "../../components/WHTable";
import { Card, CardHeader, CardBody, Button } from "reactstrap";
import * as maintenanceService from "../../services/maintenance";

class Parameters extends Component {
	editPopup = React.createRef();

	state = {
		data: [],
		col: [],
		pages: null,
		loaded: true,
		modalShow: false,
	};


	load = state => {
		this.setState({
			loaded: false,
		});

		return maintenanceService.search(state).then(data => {

		  console.log('log', data);


			this.setState(
				{
					data: [],
				},
				() => {
					this.setState({
						data: data,

						loaded: true,
					});
				},
			);
		});
	};


  componentWillMount() {
    this.load();
  }

  newData = data => {
    this.load();

  };


	render() {
		const { data, pages } = this.state;

		console.log("data.site", data.site)
		return (
			<div className="animated fadeIn">
        <Card>

          <CreateEditPopup
            ref={this.editPopup}
            show={this.state.modalShow}
            newData={this.newData}
          />

          <CardHeader>
            <i className="fa fa-align-justify" />
            Gérer le deploiement
            <div className="card-actions">
              <button
                onClick={() =>
                  this.editPopup.current.handleOpen()
                }
                className={"btn btn-success"}
              >
                <i className={"fa fa-plus"} />
                &nbsp;Nouveau
              </button>
            </div>
          </CardHeader>


          <div style={  {  padding: "20px" } }>
            <ul>
              <li>Site en déploiement : {data.site === false ? 'Non' : 'oui'}</li>
              <li>Api en déploiement : {data.api === false ? 'Non' : 'oui'}</li>
              <li>Connect en déploiement : {data.connect === false ? 'Non' : 'oui'}</li>
            </ul>

          </div>


        </Card>



			</div>
		);
	}
}

export default Parameters;
