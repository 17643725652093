const partners01 = {
    //Cette partie à externaliser en liste de blocs (issue de SF ?)
    tplt: "/page/partners/partners01.html.twig",
    admin: {
        editable: true,
        add: false,
        template: "BlocContainer",
        title: "Titre",
        fields: {
            title: {
                admin: {
                    template: "BlocTitle",
                    content:
                        '<h2 class="size-title weight-light">Rejoignez notre réseau de partenaires</h2>',
                },
            },
            subtitle: {
                admin: {
                    template: "BlocTitle",
                    content:
                        '<h2 class="size-title weight-bold">et travaillez avec des entreprises qui vous ressemblent !</h2>',
                },
            },
        },
    },
};

/**
 * EXPORT
 */
export default {
    template: "partners",
    name: "Nos partenaires",
    editable: true,
    add: false,
    seo: {
        robots: "index,follow",
    },
    builder: [partners01],
};
