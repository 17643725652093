import React from "react";
import ApiConfig from "../../../../../constants/ApiConfig";

class MediaContent extends React.Component {
	render = () => {
		const { data } = this.props;
		if (data.admin && data.admin.type == "tplt-video") {
			return <div dangerouslySetInnerHTML={{ __html: data.media }} />;
		}
		if (data.admin && data.admin.type == "tplt-document") {
			return <div>{data.media.url}</div>;
		}
		return (
			<img src={`${ApiConfig.finderParams.assetUrl}${data.media.url}`} />
		);
	};
}

export default MediaContent;
